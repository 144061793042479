import i18n from '@/i18n';

import {
  MANAGER,
  DEPARTMENT_HEAD,
  OWNER,
  CEO,

} from '@/constants/listStaffRoles';

import {
  UB_CONTACT,
  UB_CLIENT,
  UB_BUSINESS_DEVELOPMENT,
  UB_CONTENT,
  UB_INNOVATIONS,

} from '@/constants/listStaffDepartments';

const staffRolesWithDepartment = [
  {
    key: OWNER,
    text: i18n.t('staffRoles.owner'),
  },
  {
    key: CEO,
    text: i18n.t('staffRoles.ceo'),
  },
  {
    key: `${DEPARTMENT_HEAD}-${UB_CONTACT}`,
    text: i18n.t('staffRolesWithDepartment.departmentHeadContact'),
  },
  {
    key: `${MANAGER}-${UB_CONTACT}`,
    text: i18n.t('staffSalesRoles.manager'),
  },
  {
    key: `${DEPARTMENT_HEAD}-${UB_CLIENT}`,
    text: i18n.t('staffRolesWithDepartment.departmentHeadClient'),
  },
  {
    key: `${MANAGER}-${UB_CLIENT}`,
    text: i18n.t('staffCustomerServiceRoles.manager'),
  },
  {
    key: `${DEPARTMENT_HEAD}-${UB_BUSINESS_DEVELOPMENT}`,
    text: i18n.t('staffBusinessDevelopmentRoles.departmentHead'),
  },
  {
    key: `${MANAGER}-${UB_BUSINESS_DEVELOPMENT}`,
    text: i18n.t('staffBusinessDevelopmentRoles.manager'),
  },
  {
    key: `${MANAGER}-${UB_CONTENT}`,
    text: i18n.t('staffContentRoles.manager'),
  },
  {
    key: `${MANAGER}-${UB_INNOVATIONS}`,
    text: i18n.t('staffInnovationsRoles.manager'),
  },
];
export default staffRolesWithDepartment;
