import i18n from '@/i18n';

const defaultStyles = {
  fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
  fontSize: '14px',
  lineHeight: '120%',
  minHeight: '120px',
};

const defaultStylesInline = 'font-family: ubuntu, Verdana, Segoe, sans-serif;font-size: 14px;'
    + 'line-height: 120%;min-height: 120px';

export const joditConfig = {
  autofocus: false,
  enter: 'div',
  toolbar: false,
  defaultMode: 1,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  placeholder: i18n.t('servicesPage.addServiceModal.description'),
  disablePlugins: 'search, source, stat, size, tablekeyboardnavigation,'
    + 'tooltip, addnewline, enter',
  style: defaultStyles,
};

export default joditConfig;

export { defaultStylesInline };
