<template>
  <div
    class="ub-radio"
    :class="[
      customClass,
      {'ub-radio_checked': checked},
      {'ub-radio_border': border},
      {'ub-radio_border_active': checked}
    ]"
    @click.stop="statusRadio"
  >
    <span
      class="ub-radio-container"
    >
      <input
        class="ub-radio-container__input"
        type="radio"
        :name="name"
        :checked="checked"
      >
      <span class="ub-radio-container__checkmark-wrapper">
        <span></span>
      </span>
    </span>
    <textInput
      v-if="stepsField"
      :typeInput="'text'"
      :stepsField="stepsField"
      :autofocus="true"
      class="ub-radio__text-field"
      :value.sync="radioTextModel"
      :placeholderText="radioPlaceholder || $t('elements.radiobutton')"
      @inputFocusStatus="$emit('inputFocusStatus', $event)"
    />
    <span
      v-else-if="radioText || radioPlaceholder"
      class="ub-radio__text"
      :class="{'ub-radio__text_active': checked}"
    >
      {{ radioText || radioPlaceholder }}
    </span>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';

export default {
  name: 'Radio',
  components: {
    textInput,
  },
  props: ['value', 'name', 'radioText', 'checked', 'stepsField', 'border', 'customClass', 'radioPlaceholder'],
  computed: {
    radioTextModel: {
      get() {
        return this.radioText;
      },
      set(data) {
        this.$emit('update:radioText', data);
      },
    },
  },
  methods: {
    statusRadio(e) {
      e.stopPropagation();
      if (!this.stepsField) {
        this.$emit('radioChange', this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../sass/variables";

  .ub-radio {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 16px;
    cursor: pointer;
    transition: 0.3s;

    &_full {
      width: 100%;
      height: 100%;
      padding: 12px;
    }

    &-container {
      position: relative;
      cursor: pointer;
      width: 16px;
      height: 16px;
      top: -1px;

      &__checkmark-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        border: 1px solid $color-dodger-blue;
        box-sizing: border-box;
        border-radius: 50%;
      }

      &__checkmark {
        font-size: 0;
        color: $color-white;
        transition: all 150ms;
      }

      &__input {
        position: absolute;
        top:0;
        left: 0;
        height: 16px;
        width: 16px;
        margin: 0;
        opacity: 0;

        &:checked + span {

          span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-dodger-blue;
          }
        }
      }

    }

    &__text {
      margin-left: 6px;
      font: $font-size-md $font-global;
      line-height: 1.5;
      user-select: none;

      &_ellipsis {
        max-width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font: $font-size-base $font-global;
      }

      &_active {
        color: $color-dodger-blue;
      }
    }

    &__text-field {
      margin-left: 6px;
      flex-grow: 1;
    }

    &_alt {
      border: 1px solid $color-gallery;
      border-radius: $borderRadius;
      width: 100%;
      height: 40px;
      padding: 12px;
      font: $font-size-base $font-global;
      line-height: 1.42;
      transition: border-color .15s ease-in;

      span {
        font-size: $font-size-base;
        color: $color-black;
        transition: color .15s ease-in;
      }

      &:hover {
        border-color: $color-dodger-blue;

        span {
          color: $color-dodger-blue;
        }
      }
    }

    &_checked {
      span {
        color: $color-dodger-blue;
      }
    }

    &.render {
      .ub-radio {
        &__text {
          font: $font-size-base $font-global;
          line-height: 1;
          position: relative;
          padding-left: 5px;
        }
      }
    }
  }
</style>
