<template>
  <div class="admin-modal">
    <loader v-if="loader" />
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          <template v-if="!editStaffStatus">
            {{ $t('staffPage.buttonText') }}
          </template>
          <template v-else>
            {{ $t('staffModal.editTitle') }}
          </template>
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <VuePerfectScrollbar>
        <div class="admin-modal-wrap-content">
          <div class="admin-form-field">
            <div class="admin-form-field__label">
              {{ $t('staffModal.name') }}
            </div>
            <textInput
              :typeInput="'text'"
              :autofocus="true"
              :value.sync="currentStaff.firstName"
              :class="{'error': errors.has('firstName')}"
              :errorStatus="errors.has('firstName')"
              :textCapitalize="true"
            >
            </textInput>
            <transition name="fade-validation">
              <span
                v-if="errors.has('firstName')"
                class="validation"
              >
                {{ errors.first('firstName') }}
              </span>
            </transition>
          </div>
          <div class="admin-form-field">
            <div class="admin-form-field__label">
              {{ $t('staffModal.surname') }}
            </div>
            <textInput
              :typeInput="'text'"
              :value.sync="currentStaff.lastName"
              :class="{'error': errors.has('lastName')}"
              :errorStatus="errors.has('lastName')"
              :textCapitalize="true"
            >
            </textInput>
            <transition name="fade-validation">
              <span
                v-if="errors.has('lastName')"
                class="validation"
              >
                {{ errors.first('lastName') }}
              </span>
            </transition>
          </div>
          <div
            v-if="!headUbContact && !headUbClient && !headUbBusiness"
            class="admin-form-field"
          >
            <div class="admin-form-field__label">
              {{ $t('staffModal.department') }}
            </div>
            <selectWithKeyTemplate
              :settings.sync="selectSettingsDepartment"
              :class="{'error': errors.has('department')}"
              @changeSelect="changeSelectDepartment($event)"
            />
            <transition name="fade-validation">
              <span
                v-if="errors.has('department')"
                class="validation"
              >
                {{ errors.first('department') }}
              </span>
            </transition>
          </div>
          <div class="admin-form-field">
            <div class="admin-form-field__label">
              {{ $t('staffModal.role') }}
            </div>
            <selectWithKeyTemplate
              v-if="!headUbContact && !headUbClient && !headUbBusiness"
              :settings.sync="selectSettings"
              :class="{'error': errors.has('role')}"
              @changeSelect="changeSelect($event)"
            />
            <textInput
              v-else
              :typeInput="'text'"
              :value.sync="!isManager ? currentStaff.role : currentStaff.translatedRoles"
              :textCapitalize="true"
              :disabled="true"
            >
            </textInput>
            <transition name="fade-validation">
              <span
                v-if="errors.has('role')"
                class="validation"
              >
                {{ errors.first('role') }}
              </span>
            </transition>
          </div>
          <div class="admin-form-field">
            <div class="admin-form-field__label">
              {{ $t('partnersPage.email') }}
            </div>
            <textInput
              :typeInput="'email'"
              :value.sync="currentStaff.email"
              :class="{'error': $validator.errors.has('email')}"
              :errorStatus="$validator.errors.has('email')"
            >
            </textInput>
            <transition name="fade-content">
              <span
                v-show="$validator.errors.has('email')"
                class="validation"
              >
                {{ $validator.errors.first('email') }}
              </span>
            </transition>
            <transition name="fade-content">
              <div
                v-if="validationError.status"
                class="validation"
              >
                {{ validationError.text }}
              </div>
            </transition>
          </div>
        </div>
      </VuePerfectScrollbar>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="addNewStaff"
          >
            <template v-if="editStaffStatus">
              {{ $t('buttons.save') }}
            </template>
            <template v-else>
              {{ $t('buttons.add') }}
            </template>
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import textInput from '@/components/fields/textInput';
import userApi from '@/api/user/userApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import generator from 'generate-password';
import { findIndex } from 'lodash';
import loader from '@/components/loader';
import staffRolesAll from '@/constants/staffRoles';
import staffSalesRoles from '@/constants/staffSalesRoles';
import staffDepartments from '@/constants/staffDepartments';
import staffCustomerServiceRoles from '@/constants/staffCustomerServiceRoles';
import staffAgentsRoles from '@/constants/staffAgentsRoles';
import staffBusinessDevelopmentRoles from '@/constants/staffBusinessDevelopmentRoles';
import staffInnovationsRoles from '@/constants/staffInnovationsRoles';
import staffContentRoles from '@/constants/staffContentRoles';
import staffOutsideDepartmentRoles from '@/constants/staffOutsideDepartmentRoles';
import {
  DEPARTMENT_HEAD, OWNER, CEO, MANAGER,
} from '@/constants/roleType';
import {
  UB_CONTACT, UB_CLIENT, UB_BUSINESS_DEVELOPMENT, UB_AGENTS, UB_CONTENT,
  UB_INNOVATIONS, UB_OUTSIDE_DEPARTMENT, UB_SERVICES,
} from '@/constants/listStaffDepartments';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'StaffsModal',
  components: {
    textInput,
    selectWithKeyTemplate,
    loader,
    VuePerfectScrollbar,
  },
  props: {
    staffList: {
      type: Array,
      default: () => [],
    },
    editStaff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      staffDepartments,
      loader: false,
      validationError: {
        text: '',
        status: false,
      },
      editStaffStatus: false,
      roles: staffRolesAll,
      currentStaff: {
        firstName: '',
        lastName: '',
        department: '',
        email: '',
        role: '',
        password: generator.generate({
          length: 10,
          numbers: true,
        }),
      },
    };
  },
  computed: {
    isManager() {
      return this.currentStaff.role === MANAGER;
    },
    headUbContact() {
      return this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_CONTACT;
    },
    headUbClient() {
      return this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_CLIENT;
    },
    headUbBusiness() {
      return this.userRole === DEPARTMENT_HEAD && this.userDepartment === UB_BUSINESS_DEVELOPMENT;
    },
    selectSettings() {
      return {
        optionList: this.roles,
        placeholderText: this.$t('staffModal.placeholderSelect'),
        selectedKey: this.currentStaff.role,
        disable: !this.currentStaff.department,
        toTop: false,
        tabindex: 1,
        readOnly: false,
      };
    },
    selectSettingsDepartment() {
      return {
        optionList: staffDepartments,
        placeholderText: this.$t('staffModal.placeholderSelectDepartment'),
        selectedKey: this.currentStaff.department,
        disable: false,
        toTop: false,
        tabindex: 1,
        readOnly: false,
      };
    },
    language() {
      return this.$i18n.locale;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
  },
  beforeMount() {
    if (this.headUbContact || this.headUbClient || this.headUbBusiness) {
      this.currentStaff.role = MANAGER;
      if (this.userDepartment === UB_BUSINESS_DEVELOPMENT) {
        this.currentStaff.translatedRoles = this.$t('staffBusinessDevelopmentRoles.manager');
      }
      if (this.userDepartment === UB_CONTACT) {
        this.currentStaff.translatedRoles = this.$t('staffSalesRoles.manager');
      }
      if (this.userDepartment === UB_CLIENT) {
        this.currentStaff.translatedRoles = this.$t('staffCustomerServiceRoles.manager');
      }
      if (this.userDepartment === UB_CONTENT) {
        this.currentStaff.translatedRoles = this.$t('staffContentRoles.manager');
      }
    }
    if (this.userRole === CEO) {
      const currentStaffIndex = findIndex(this.roles, (
        (staff) => staff.key === OWNER));
      this.roles.splice(currentStaffIndex, 1);
    }
    const dict = {
      en: {
        custom: {
          firstName: {
            required: validationErrorMessage.en.inputRequired,
          },
          lastName: {
            required: validationErrorMessage.en.inputRequired,
          },
          position: {
            required: validationErrorMessage.en.inputRequired,
          },
          role: {
            required: validationErrorMessage.en.inputRequired,
          },
          department: {
            required: validationErrorMessage.en.inputRequired,
          },
          country: {
            required: validationErrorMessage.en.inputRequired,
          },
          email: {
            required: validationErrorMessage.en.inputRequired,
            email: validationErrorMessage.en.inputEmail,
          },
        },
      },
      ru: {
        custom: {
          firstName: {
            required: validationErrorMessage.ru.inputRequired,
          },
          lastName: {
            required: validationErrorMessage.ru.inputRequired,
          },
          position: {
            required: validationErrorMessage.ru.inputRequired,
          },
          role: {
            required: validationErrorMessage.ru.inputRequired,
          },
          department: {
            required: validationErrorMessage.ru.inputRequired,
          },
          country: {
            required: validationErrorMessage.ru.inputRequired,
          },
          email: {
            required: validationErrorMessage.ru.inputRequired,
            default: validationErrorMessage.ru.inputEmail,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'firstName', rules: { required: true } });
    this.$validator.attach({ name: 'lastName', rules: { required: true } });
    this.$validator.attach({ name: 'department', rules: { required: true } });
    this.$validator.attach({ name: 'role', rules: { required: true } });
    this.$validator.attach({
      name: 'email',
      rules: {
        required: true,
        // eslint-disable-next-line max-len
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    });
    if (Object.keys(this.editStaff).length) {
      this.currentStaff = {
        ...this.editStaff,
      };
      if (this.currentStaff.department === undefined) {
        this.currentStaff.department = UB_OUTSIDE_DEPARTMENT;
      }
      switch (this.editStaff.department) {
      case 'ubContact':
        this.roles = staffSalesRoles;
        break;
      case 'ubClient':
        this.roles = staffCustomerServiceRoles;
        break;
      case 'ubContent':
        this.roles = staffContentRoles;
        if (this.roles.length === 1) {
          const { key } = this.roles[0];
          this.currentStaff.role = key;
        }
        break;
      case 'ubAgents':
        this.roles = staffAgentsRoles;
        break;
      case 'ubBusinessDevelopment':
        this.roles = staffBusinessDevelopmentRoles;
        break;
      case 'ubInnovations':
        this.roles = staffInnovationsRoles;
        if (this.roles.length === 1) {
          const { key } = this.roles[0];
          this.currentStaff.role = key;
        }
        break;
      case undefined:
        this.roles = staffOutsideDepartmentRoles;
        break;
      default:
        break;
      }
      this.changeSelect(this.editStaff.role);
      this.editStaffStatus = true;
    } else {
      this.editStaffStatus = false;
    }
  },
  methods: {
    addServiceQuery() {
      this.loader = true;
      if (this.headUbContact || this.headUbClient || this.headUbBusiness) {
        this.currentStaff.department = this.userDepartment;
        this.currentStaff.role = MANAGER;
      }
      const { role, department } = this.currentStaff;
      const isDepartmentAndRole = role === OWNER || role === CEO;
      userApi.createPartner({
        ...this.currentStaff,
        department: isDepartmentAndRole ? undefined : department,
      }).then((resp) => {
        const {
          firstName,
          lastName = '',
          role,
          department,
          // eslint-disable-next-line global-require
          userAvatarUrl = require('@/assets/images/placeholder/avatar.svg'),
        } = resp.data;
        const fullName = `${firstName} ${lastName}`;
        let translatedRoles = '';
        switch (role) {
        case OWNER:
          translatedRoles = this.$t('roles.owner');
          break;
        case MANAGER:
          if (department === UB_BUSINESS_DEVELOPMENT) {
            translatedRoles = this.$t('staffBusinessDevelopmentRoles.manager');
          }
          if (department === UB_CONTACT) {
            translatedRoles = this.$t('staffSalesRoles.manager');
          }
          if (department === UB_CLIENT) {
            translatedRoles = this.$t('staffCustomerServiceRoles.manager');
          }
          if (department === UB_CONTENT) {
            translatedRoles = this.$t('staffContentRoles.manager');
          }
          if (department === UB_INNOVATIONS) {
            translatedRoles = this.$t('staffInnovationsRoles.manager');
          }
          break;
        case CEO:
          translatedRoles = 'CEO';
          break;
        case DEPARTMENT_HEAD:
          if (department === UB_BUSINESS_DEVELOPMENT) {
            translatedRoles = this.$t('staffBusinessDevelopmentRoles.departmentHead');
          }
          if (department === UB_CONTACT) {
            translatedRoles = this.$t('staffSalesRoles.departmentHead');
          }
          if (department === UB_CLIENT) {
            translatedRoles = this.$t('staffCustomerServiceRoles.departmentHead');
          }
          break;
        default:
          break;
        }
        let translatedDepartment = '';
        switch (department) {
        case UB_CONTACT:
          translatedDepartment = this.$t('staffDepartments.contact');
          break;
        case UB_CLIENT:
          translatedDepartment = this.$t('staffDepartments.client');
          break;
        case UB_AGENTS:
          translatedDepartment = this.$t('staffDepartments.agents');
          break;
        case UB_BUSINESS_DEVELOPMENT:
          translatedDepartment = this.$t('staffDepartments.businessDevelopment');
          break;
        case UB_CONTENT:
          translatedDepartment = this.$t('staffDepartments.content');
          break;
        case UB_INNOVATIONS:
          translatedDepartment = this.$t('staffDepartments.innovations');
          break;
        case UB_SERVICES:
          translatedDepartment = this.$t('staffDepartments.services');
          break;
        default:
          break;
        }
        this.staffList.unshift({
          ...resp.data,
          fullName,
          userAvatarUrl,
          translatedRoles,
          translatedDepartment,
        });
        this.loader = false;
        this.$emit('closeAddNewStaff');
      })
        .catch((err) => {
          this.loader = false;
          const str = err.response.data.message;
          if (err.response && (str.includes('User already exists'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationText'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          if (err.response && (str.includes('Please fill a valid email address'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationTextEmail'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          console.error(err);
        });
    },
    saveServiceQuery() {
      const { department } = this.currentStaff;
      const staff = {
        ...this.currentStaff,
        fullName: undefined,
        translatedRoles: undefined,
        translatedDepartment: undefined,
        department: this.userRole === CEO || this.userRole === OWNER ? undefined : department,
      };
      this.loader = true;
      userApi.updateUser(staff)
        .then((resp) => {
          this.loader = false;
          const { role, department } = resp.data;
          let translatedRoles = '';
          switch (role) {
          case OWNER:
            translatedRoles = this.$t('roles.owner');
            break;
          case MANAGER:
            if (department === UB_BUSINESS_DEVELOPMENT) {
              translatedRoles = this.$t('staffBusinessDevelopmentRoles.manager');
            }
            if (department === UB_CONTACT) {
              translatedRoles = this.$t('staffSalesRoles.manager');
            }
            if (department === UB_CLIENT) {
              translatedRoles = this.$t('staffCustomerServiceRoles.manager');
            }
            if (department === UB_CONTENT) {
              translatedRoles = this.$t('staffContentRoles.manager');
            }
            if (department === UB_INNOVATIONS) {
              translatedRoles = this.$t('staffInnovationsRoles.manager');
            }
            break;
          case CEO:
            translatedRoles = 'CEO';
            break;
          case DEPARTMENT_HEAD:
            if (department === UB_BUSINESS_DEVELOPMENT) {
              translatedRoles = this.$t('staffBusinessDevelopmentRoles.departmentHead');
            }
            if (department === UB_CONTACT) {
              translatedRoles = this.$t('staffSalesRoles.departmentHead');
            }
            if (department === UB_CLIENT) {
              translatedRoles = this.$t('staffCustomerServiceRoles.departmentHead');
            }
            break;
          default:
            break;
          }
          let translatedDepartment = '';
          switch (department) {
          case UB_CONTACT:
            translatedDepartment = this.$t('staffDepartments.contact');
            break;
          case UB_CLIENT:
            translatedDepartment = this.$t('staffDepartments.client');
            break;
          case UB_AGENTS:
            translatedDepartment = this.$t('staffDepartments.agents');
            break;
          case UB_BUSINESS_DEVELOPMENT:
            translatedDepartment = this.$t('staffDepartments.businessDevelopment');
            break;
          case UB_CONTENT:
            translatedDepartment = this.$t('staffDepartments.content');
            break;
          case UB_INNOVATIONS:
            translatedDepartment = this.$t('staffDepartments.innovations');
            break;
          case UB_OUTSIDE_DEPARTMENT:
            translatedDepartment = this.$t('staffDepartments.outside');
            break;
          default:
            break;
          }
          const currentStaffIndex = findIndex(this.staffList, (
            (staff) => staff._id === this.editStaff._id));
          this.staffList.splice(currentStaffIndex, 1, {
            ...resp.data,
            fullName: `${resp.data.firstName} ${resp.data.lastName}`,
            translatedRoles,
            translatedDepartment,
          });
          this.$emit('closeAddNewStaff');
        })
        .catch((err) => {
          this.loader = false;
          const str = err.response.data.message;
          if (err.response && (str.includes('duplicate key error'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationText'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          console.error(err);
        });
    },
    removeValidationError(error) {
      if (this.$validator.errors && this.$validator.errors.items) {
        const fieldError = this.$validator.errors.items.find((item) => item.field === error);
        if (fieldError) {
          this.$validator.errors.removeById(fieldError.id);
        }
      }
    },
    closeModal() {
      this.$emit('closeAddNewStaff');
    },
    addNewStaff() {
      const {
        firstName, lastName, role, email, department,
      } = this.currentStaff;
      this.$validator.validateAll({
        firstName,
        lastName,
        department: this.userRole !== DEPARTMENT_HEAD ? department : true,
        role,
        email,
      }).then((result) => {
        if (result) {
          if (this.editStaffStatus) {
            this.saveServiceQuery();
          } else {
            this.addServiceQuery();
          }
        }
      });
    },
    changeSelect(key) {
      this.currentStaff.role = key;
    },
    changeSelectDepartment(key) {
      this.currentStaff.department = key;
      switch (key) {
      case 'ubContact':
        this.roles = staffSalesRoles;
        break;
      case 'ubClient':
        this.roles = staffCustomerServiceRoles;
        break;
      case 'ubContent':
        this.roles = staffContentRoles;
        if (this.roles.length === 1) {
          const { key } = this.roles[0];
          this.currentStaff.role = key;
        }
        break;
      case 'ubAgents':
        this.roles = staffAgentsRoles;
        break;
      case 'ubBusinessDevelopment':
        this.roles = staffBusinessDevelopmentRoles;
        break;
      case 'ubInnovations':
        this.roles = staffInnovationsRoles;
        if (this.roles.length === 1) {
          const { key } = this.roles[0];
          this.currentStaff.role = key;
        }
        break;
      case 'ubOutsideDepartment':
        this.roles = staffOutsideDepartmentRoles;
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;
}

</style>
