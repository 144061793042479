import i18n from '@/i18n';
import {
  AUD,
  BRL,
  CAD,
  CNY,
  CZK,
  DKK,
  EUR,
  HKD,
  HUF,
  INR,
  ILS,
  JPY,
  MYR,
  MXN,
  TWD,
  NZD,
  NOK,
  PHP,
  PLN,
  GBP,
  RUB,
  SGD,
  SEK,
  CHF,
  THB,
  USD,
} from '@/constants/listFieldsCurrency';

const currency = [
  {
    text: i18n.t('currency.australianDollar'),
    key: AUD,
  },
  {
    text: i18n.t('currency.brazilianReal'),
    key: BRL,
  },
  {
    text: i18n.t('currency.canadianDollar'),
    key: CAD,
  },
  {
    text: i18n.t('currency.chineseRenmenbi'),
    key: CNY,
  },
  {
    text: i18n.t('currency.czechKoruna'),
    key: CZK,
  },
  {
    text: i18n.t('currency.danishKrone'),
    key: DKK,
  },
  {
    text: i18n.t('currency.euro'),
    key: EUR,
  },
  {
    text: i18n.t('currency.hongKongDollar'),
    key: HKD,
  },
  {
    text: i18n.t('currency.hungarianForint'),
    key: HUF,
  },
  {
    text: i18n.t('currency.indianRupee'),
    key: INR,
  },
  {
    text: i18n.t('currency.israeliNewShekel'),
    key: ILS,
  },
  {
    text: i18n.t('currency.japaneseYen'),
    key: JPY,
  },
  {
    text: i18n.t('currency.malaysianRinggit'),
    key: MYR,
  },
  {
    text: i18n.t('currency.mexicanPeso'),
    key: MXN,
  },
  {
    text: i18n.t('currency.newTaiwanDollar'),
    key: TWD,
  },
  {
    text: i18n.t('currency.newZealandDollar'),
    key: NZD,
  },
  {
    text: i18n.t('currency.norwegianKrone'),
    key: NOK,
  },
  {
    text: i18n.t('currency.philippinePeso'),
    key: PHP,
  },
  {
    text: i18n.t('currency.polishZloty'),
    key: PLN,
  },
  {
    text: i18n.t('currency.poundSterling'),
    key: GBP,
  },
  {
    text: i18n.t('currency.russianRuble'),
    key: RUB,
  },
  {
    text: i18n.t('currency.singaporeDollar'),
    key: SGD,
  },
  {
    text: i18n.t('currency.swedishKrona'),
    key: SEK,
  },
  {
    text: i18n.t('currency.swissFranc'),
    key: CHF,
  },
  {
    text: i18n.t('currency.thaiBaht'),
    key: THB,
  },
  {
    text: i18n.t('currency.unitedStatesDollar'),
    key: USD,
  },
];
export default currency;
