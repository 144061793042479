import {
  IT,
  HOTELS,
  RESTAURANTS,
  WHOLESALE,
  FRANCHISE,
  INSURANCE,
  PAYMENT_SYSTEMS,
  RETAIL,
  GAMBLE,
  DEALERSHIP_AND_BROKING,
  ADVISORY_SERVICES,
  CONSTRUCTION,
  TRANSPOURT_AND_LOGISTICS,
  LEASE,
  MINERALS_EXTRACTION,
  AGRICULTURE,
  MANUFACTURE,
  OTHER,
} from '@/constants/listFieldsActivity';

const fieldOfActivity = [
  {
    key: IT,
    text: 'servicesPage.addServiceModal.it',
  },
  {
    key: HOTELS,
    text: 'servicesPage.addServiceModal.hotelsAndHotels',
  },
  {
    key: RESTAURANTS,
    text: 'servicesPage.addServiceModal.restaurantsAndCafes',
  },
  {
    key: WHOLESALE,
    text: 'servicesPage.addServiceModal.wholesale',
  },
  {
    key: FRANCHISE,
    text: 'servicesPage.addServiceModal.franchiseCompany',
  },
  {
    key: INSURANCE,
    text: 'servicesPage.addServiceModal.insuranceCompany',
  },
  {
    key: PAYMENT_SYSTEMS,
    text: 'servicesPage.addServiceModal.paymentSystem',
  },
  {
    key: RETAIL,
    text: 'servicesPage.addServiceModal.retail',
  },
  {
    key: GAMBLE,
    text: 'servicesPage.addServiceModal.gambling',
  },
  {
    key: DEALERSHIP_AND_BROKING,
    text: 'servicesPage.addServiceModal.brokerage',
  },
  {
    key: ADVISORY_SERVICES,
    text: 'servicesPage.addServiceModal.profConsultants',
  },
  {
    key: CONSTRUCTION,
    text: 'servicesPage.addServiceModal.building',
  },
  {
    key: TRANSPOURT_AND_LOGISTICS,
    text: 'servicesPage.addServiceModal.transportAndLogistic',
  },
  {
    key: LEASE,
    text: 'servicesPage.addServiceModal.leasingCompany',
  },
  {
    key: MINERALS_EXTRACTION,
    text: 'servicesPage.addServiceModal.extraction',
  },
  {
    key: AGRICULTURE,
    text: 'servicesPage.addServiceModal.agriculture',
  },
  {
    key: MANUFACTURE,
    text: 'servicesPage.addServiceModal.manufacturing',
  },
  {
    key: OTHER,
    text: 'servicesPage.addServiceModal.other',
  },
];
export default fieldOfActivity;
