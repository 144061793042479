<template>
  <div
    class="elements"
  >
    <div class="elements__list">
      <div
        v-for="elem in elementList"
        :key="elem.id"
        class="elements-item"
        @click="addElementToStep(elem)"
      >
        <div class="elements-item__image">
          <i :class="elem.image"></i>
        </div>
        <div class="elements-item__name">
          {{ elem.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listElToFunc from '@/preset/elements/listElementsToFunction';
import { findIndex } from 'lodash';

export default {
  name: 'AllElements',
  props: {
    openWindowElementsProps: {
      type: [Number],
    },
    elementList: {
      type: Array,
      default: () => [],
    },
    allElementsProps: {
      type: Array,
      default: () => [],
    },
    indexElement: {
      type: Number,
    },
    allSteps: {
      type: Object,
      default: () => [],
    },
    stepId: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    allElements: {
      get() {
        return this.allElementsProps;
      },
      set(data) {
        this.$emit('update:allElementsProps', data);
      },
    },
    openWindowElements: {
      get() {
        return this.openWindowElementsProps;
      },
      set(data) {
        this.$emit('update:openWindowElementsProps', data);
      },
    },
  },
  methods: {
    addElementToStep(elem) {
      const element = listElToFunc[elem.sub]();
      const currentStepIndex = findIndex(this.allSteps.en, ((step) => step.stepId === this.stepId));
      this.allSteps.en[currentStepIndex].fields.splice(this.indexElement + 1, 0, element);
      this.allSteps.ru[currentStepIndex].fields.splice(this.indexElement + 1, 0, element);
      this.openWindowElements = -1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";

.elements {
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px rgba($color-black, .1);
  border-radius: $borderRadius;
  background: $color-white;
  max-width: 440px;
  padding: 10px;
  width: 100%;
  z-index: $z-index-small;
  user-select: none;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-item {
    margin: 6px;
    cursor: pointer;
    min-width: 58px;
    max-width: 58px;
    overflow: hidden;

    &__name {
      font: $font-size-sm $font-global-medium;
      line-height: 1.55;
      color: $color-silver-chalice;
      text-align: center;
      margin-top: 4px;
    }

    &__image {
      min-width: 58px;
      max-width: 58px;
      min-height: 58px;
      margin-bottom: 6px;
      max-height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($color-dodger-blue, .08);
      border-radius: $borderRadius;
      color: $color-silver-chalice;
      transition: color .15s ease-in;

      i {
        font-size: $font-size-huge;
      }

      .ub-icon-checkbox {
        background: $color-silver-chalice;
        color: $color-white;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $borderRadiusSmall;
        transition: background-color .15s ease-in;

        &:hover {
          background: $color-dodger-blue;
        }
      }

      &:hover {
        color: $color-dodger-blue;

        .ub-icon-checkbox {
          background: $color-dodger-blue;
        }
      }
    }
  }
}
</style>
