<template>
  <div
    class="specification-settings"
  >
    <div
      class="specification-settings__wrapper"
    >
      <div class="specification-settings__row">
        <div class="specification-settings__form specification-settings__form_width">
          <textInput
            :labelText="$t('servicesPage.addServiceModal.nameOfSpecification')"
            :typeInput="'text'"
            :autofocus="true"
            :value.sync="currentSpecificationTitle"
            :class="{'error': $validator.errors.has('name')}"
            :errorStatus="$validator.errors.has('name')"
          >
          </textInput>
          <transition name="fade-el">
            <span
              v-show="$validator.errors.has('name')"
              class="validation"
            >
              {{ $validator.errors.first('name') }}
            </span>
          </transition>
        </div>
      </div>
      <div
        class="specification-settings__row specification-settings__row_align"
      >
        <div class="specification-settings__form">
          <div class="admin-form-field">
            <div class="admin-form-field__label">
              {{ $t('elements.fieldType') }}
            </div>
            <selectWithKeyTemplate
              :settings="typeServiceSelectSettings"
              :class="{'error': errors.has('setActiveTypeService')}"
              @changeSelect="changeTypeService($event)"
            />
            <transition name="fade-validation">
              <span
                v-if="errors.has('setActiveTypeService')"
                class="validation"
              >
                {{ errors.first('setActiveTypeService') }}
              </span>
            </transition>
          </div>
        </div>
        <template v-if="typeSpecification === 'text'">
          <div class="specification-settings__form specification-settings__form_full">
            <textInput
              :typeInput="'text'"
              :autofocus="false"
              :value.sync="currentSpecificationValue"
              :class="{'error': $validator.errors.has('valueFrom')}"
              :errorStatus="$validator.errors.has('valueFrom')"
            >
            </textInput>
            <transition name="fade-el">
              <span
                v-show="$validator.errors.has('valueFrom')"
                class="validation"
              >
                {{ $validator.errors.first('valueFrom') }}
              </span>
            </transition>
          </div>
        </template>
        <template v-if="typeSpecification === 'range'">
          <div class="specification-settings__form specification-settings__form_from">
            <textInput
              :labelText="$t('servicesPage.addServiceModal.from')"
              :typeInput="'number'"
              :autofocus="false"
              :value.sync="currentSpecificationValue[0]"
              :class="{'error': $validator.errors.has('valueFrom')}"
              :errorStatus="$validator.errors.has('valueFrom')"
            >
            </textInput>
            <transition name="fade-el">
              <span
                v-show="$validator.errors.has('valueFrom')"
                class="validation"
              >
                {{ $validator.errors.first('valueFrom') }}
              </span>
            </transition>
            <transition name="fade-validation">
              <div
                v-if="!errors.has('valueFrom')"
                class="specification-settings__icon specification-settings__icon_right"
                @click="openTypeModal(!isVisibleTypeModal)"
              >
                <span v-if="currentSpecification.rangeType === 'minmax'">
                  {{ $t('global.min') }}
                </span>
                <span v-else>
                  {{ activeType }}
                </span>
              </div>
            </transition>
            <transition name="fade-el">
              <div
                v-if="isVisibleTypeModal"
                class="modal"
              >
                <div
                  v-for="item in items"
                  :key="item.key"
                  class="modal__item"
                  @click="selectTypeField(item.key, item.text)"
                >
                  {{ item.text }}
                </div>
              </div>
            </transition>
          </div>
          <div class="specification-settings__form specification-settings__form_to">
            <textInput
              :labelText="$t('servicesPage.addServiceModal.to')"
              :typeInput="'number'"
              :value.sync="currentSpecificationValue[1]"
              :class="{'error': $validator.errors.has('valueTo')}"
              :errorStatus="$validator.errors.has('valueTo')"
            >
            </textInput>
            <transition name="fade-validation">
              <div
                v-if="!errors.has('valueFrom')"
                class="specification-settings__icon specification-settings__icon_right
                specification-settings__icon_event"
                @click="openTypeModal(!isVisibleTypeModal)"
              >
                <span v-if="currentSpecification.rangeType === 'minmax'">
                  {{ $t('global.max') }}
                </span>
                <span v-else>
                  {{ activeType }}
                </span>
              </div>
            </transition>
            <transition name="fade-el">
              <span
                v-show="$validator.errors.has('valueTo')"
                class="validation"
              >
                {{ $validator.errors.first('valueTo') }}
              </span>
            </transition>
          </div>
        </template>
        <template v-if="typeSpecification === 'short'">
          <div
            class="specification-settings__form
                       specification-settings__form_column
                       specification-settings__form_fullwidth"
          >
            <div class="specification-settings__column specification-settings__column_flex">
              <radio
                :value.sync="radioValue"
                :radioText.sync="radioTextChecked"
                :checked="currentSpecificationValue"
                :customClass="'ub-radio_alt'"
                @radioChange="changeAllowed(true)"
              />
              <radio
                :value.sync="radioValueChanged"
                :radioText.sync="radioTextNotChecked"
                :checked="!currentSpecificationValue"
                :customClass="'ub-radio_alt'"
                @radioChange="changeAllowed(false)"
              />
            </div>
          </div>
        </template>
        <template v-if="typeSpecification === 'optional'">
          <div
            class="specification-settings__wrap specification-settings__wrap_width"
          >
            <checkBox
              :value="valueCheckbox"
              :checkboxText="this.$t('global.required')"
              :customClass="'ub-checkbox_alt'"
              @changeCheckBox="changeCheckBox()"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="specification-settings-footer">
      <div class="specification-settings-footer__item">
        <button
          class="ub-btn ub-btn_cancel specification-settings-footer__cancel"
          @click="closeSpecificationSettings"
        >
          {{ $t('global.cancel') }}
        </button>
      </div>
      <div class="specification-settings-footer__item">
        <button
          v-shortkey="['enter']"
          class="ub-btn ub-btn_primary"
          :class="{'button-action_disabled': specification.type === undefined}"
          :disabled="specification.type === undefined"
          @click="saveSpecification"
          @shortkey="saveSpecification"
        >
          <template v-if="currentSpecification.newSpecification">
            {{ $t('buttons.add') }}
          </template>
          <template v-else>
            {{ $t('buttons.save') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import ClickOutside from 'vue-click-outside';
import validationErrorMessage from '@/locales/validationErrorMessage';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import radio from '@/components/fields/radio';
import checkBox from '@/components/fields/checkBox';
import typeFieldsListModal from '@/constants/typeFieldsListModal';

export default {
  name: 'SpecificationSettings',
  components: {
    textInput,
    selectWithKeyTemplate,
    radio,
    checkBox,
  },
  directives: {
    ClickOutside,
  },
  props: {
    specification: {
      type: Object,
      default: () => {},
    },
    currencyService: {
      type: String,
      default: 'EUR',
    },
    specificationServices: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        {
          text: this.currencyService,
          key: 'currency',
        },
        {
          text: this.$t('timingModal'),
          key: 'day',
        },
        {
          text: this.$t('global.minMax'),
          key: 'minmax',
        },
      ],
      typeFieldsListModal,
      typeSpecification: '',
      radioValue: 1,
      radioValueChanged: 2,
      activeType: this.currencyService,
      valueCheckbox: true,
      radioTextChecked: this.$t('global.yes'),
      radioTextNotChecked: this.$t('global.no'),
      isVisibleTypeModal: false,
    };
  },
  computed: {
    typeServiceSelectSettings() {
      return {
        optionList: this.typeFieldsListModal,
        placeholderText: this.$t('servicesPage.addServiceModal.typeServicePlaceholderField'),
        selectedKey: this.specification.type,
        disable: false,
        toTop: false,
        readOnly: true,
        tabindex: 1,
        selectClass: '',
      };
    },
    currentSpecification: {
      get() {
        return this.specification;
      },
      set(data) {
        this.$emit('update:specification', data);
      },
    },
    currentSpecificationTitle: {
      get() {
        return this.currentSpecification.name;
      },
      set(data) {
        this.currentSpecification.name = data;
      },
    },
    currentSpecificationValue: {
      get() {
        return this.currentSpecification.value;
      },
      set(data) {
        this.currentSpecification.value = data;
      },
    },
  },
  beforeMount() {
    if (this.specification.type !== undefined) {
      this.typeSpecification = this.specification.type;
    }
    if (this.specification.type === 'optional') {
      this.valueCheckbox = this.specification.value;
    }
    if (this.currentSpecification.rangeType !== undefined) {
      this.activeType = this.currentSpecification.rangeType;
    }
    if (this.specification.rangeType === 'currency') {
      this.activeType = this.currencyService;
    }
    const dict = {
      en: {
        custom: {
          name: {
            required: validationErrorMessage.en.inputRequired,
          },
          valueFrom: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          name: {
            required: validationErrorMessage.ru.inputRequired,
          },
          valueFrom: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'name', rules: { required: true } });
    this.$validator.attach({ name: 'valueFrom', rules: { required: true } });
  },
  methods: {
    selectTypeField(key, text) {
      this.currentSpecification.rangeType = key;
      if (key === 'currency') {
        this.activeType = this.currencyService;
      } else {
        this.activeType = text;
      }
      this.isVisibleTypeModal = false;
    },
    openTypeModal(data) {
      this.isVisibleTypeModal = data;
    },
    changeCheckBox() {
      this.valueCheckbox = !this.valueCheckbox;
      this.currentSpecificationValue = this.valueCheckbox;
    },
    changeAllowed(val) {
      this.currentSpecificationValue = val;
    },
    changeTypeService(key) {
      this.typeSpecification = key;
      this.currentSpecification.type = key;
      if (this.currentSpecification.type === 'range') {
        this.currentSpecification.value = [];
      } else {
        this.currentSpecification.value = '';
      }
    },
    closeSpecificationSettings() {
      this.$emit('closeSpecificationSettings', 'cancel');
    },
    saveSpecification() {
      const res = this.typeSpecification === 'optional' || this.typeSpecification === 'short'
        ? true : this.currentSpecificationValue;
      this.$validator.validateAll({
        name: this.currentSpecificationTitle,
        valueFrom: this.typeSpecification === 'range' ? this.currentSpecificationValue[0]
          : res,
      }).then((result) => {
        if (result) {
          const {
            id, name, value, type, rangeType,
          } = this.currentSpecification;
          if (this.currentSpecification.newSpecification) {
            Object.keys(this.specificationServices).forEach((key) => {
              const currentSpecification = this.specificationServices[key].find(
                (specification) => specification.id === id,
              );
              if (currentSpecification) {
                currentSpecification.newSpecification = undefined;
                if (type === 'text') {
                  currentSpecification.value = value;
                } else if (type === 'short') {
                  currentSpecification.value = this.currentSpecificationValue || false;
                } else if (type === 'optional') {
                  currentSpecification.value = this.valueCheckbox;
                } else {
                  currentSpecification.value = value;
                  currentSpecification.rangeType = rangeType || 'currency';
                }
                if (currentSpecification.name === '') {
                  currentSpecification.name = name;
                  currentSpecification.type = type;
                  currentSpecification.rangeType = rangeType;
                }
              }
            });
          }
          this.$emit('closeSpecificationSettings');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.specification-settings {
  background: rgba($color-dodger-blue, .08);
  border-radius: $borderRadius;
  padding: 14px 14px 16px 14px;
  min-height: 150px;
  margin-bottom: 8px;

  .modal {
    width: 91px;
    padding: 3px 0;
    background: $color-white;
    position: absolute;
    right: 13px;
    top: 55px;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    z-index: 1;

    &__item {
      padding: 9px 12px;
      cursor: pointer;
      transition: background .15s ease-in;
      font-size: $font-size-md;
      text-transform: capitalize;

      &:hover {
        background: $color-alabaster;
      }
    }
  }

  &__icon {
    position: absolute;
    color: $color-dodger-blue;
    right: 12px;
    top: 35px;
    cursor: pointer;

    span {
      font-family: $font-global-medium;
    }

    &_right {
      right: 15px;
      text-transform: capitalize;
    }

    &_event {
      pointer-events: none;
    }
  }

  &__column {
    display: flex;

    &_flex {
      margin-left: 8px;
    }

    .ub-radio {
      background: $color-white;
      min-width: 258px;
    }

    .specification-settings__form .ub-radio + .ub-radio {
      margin-left: 8px;
    }
  }

  &__wrap {

    &_width {
      width: 100%;
      background: $color-white;
      margin-left: 18px;
    }
  }

  &__row {
    display: flex;
    width: 100%;

    &_align {
      margin-top: 16px;
      align-items: flex-end;
    }

    .specification-settings__form {
      min-width: 34%;
      max-width: 34%;

      &_from {
        min-width: 32% !important;
        max-width: 32% !important;
      }

      &_to {
        max-width: 30% !important;
        min-width: 30% !important;
        margin-left: 24px!important;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          width: 7px;
          height: 1px;
          background: $color-black;
          left: -16px;
          top: 43px;
        }
      }

      & + .specification-settings__form {
        margin-left: 8px;
        min-width: 32%;
        max-width: 32%;
        position: relative;
      }

      &_width {
        min-width: 100%;
        max-width: 100%;
        position: relative;
      }
      &_full {
        min-width: 65% !important;
        max-width: 65% !important;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 36px;

    &__cancel {
      margin-right: 5px;
      background: transparent;
    }
  }
}
</style>
