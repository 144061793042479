import i18n from '@/i18n';

import {
  MANAGER,
} from '@/constants/listStaffRoles';

const staffContentRoles = [
  {
    key: MANAGER,
    text: i18n.t('staffContentRoles.manager'),
  },
];
export default staffContentRoles;
