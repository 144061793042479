import { uniqueId } from 'lodash';
import i18n from '@/i18n';

const columns = [
  {
    title: 'servicesPage.table.columns.name',
    pointer: true,
    prop: `name.${i18n.locale}`,
    width: 'minmax(70px, 1.3fr)',
  },
  {
    title: 'servicesPage.table.columns.managementForm',
    pointer: true,
    prop: `managementForm.${i18n.locale}`,
    width: 'minmax(70px, 1.5fr)',
  },
  {
    title: 'servicesPage.table.columns.country',
    pointer: true,
    type: 'image',
    prop: `country.name.${i18n.locale}`,
    displayTitle: `country.name.${i18n.locale}`,
    display: 'country.image',
    width: 'minmax(70px, 1fr)',
  },
  {
    title: 'servicesPage.table.columns.cost',
    pointer: true,
    prop: `steps.${i18n.locale}`,
    prefix: 'currency',
    sum: 'cost',
    justifyContent: 'flex-end',
    width: 'minmax(70px, 1fr)',
  },
  {
    type: 'toolbar',
    prop: `name.${i18n.locale}`,
    width: '26px',
    copyAction: true,
  },
];

const listSettingsServices = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsServices;
