<template>
  <section class="admin-partners-page">
    <loader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
    <UBTable
      :columns="columns"
      :rows="partnerList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 157px)'"
      :loader.sync="loader"
      :countryFlag="true"
      :clickRow="true"
      @update="preUpdateRequest"
      @delete="deleteRequest"
      @edit="openEditRequest"
      @clickByRow="goToRoute"
    />
    <UBPagination
      v-show="partnerList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
    <transition name="fade-el">
      <partnersModal
        v-if="isVisiblePartnerModal"
        :partnerList="partnerList"
        :editPartner="editPartner"
        @closeAddNewPartner="closeAddNewPartner"
      />
    </transition>
  </section>
</template>

<script>
import listSettingsPartners from '@/constants/tablesSettings/listSettingsPartners';
import queryString from 'query-string';
import { findIndex, sortBy } from 'lodash';
import adminPageHeader from '@/components/adminPageHeader';
import partnersModal from '@/components/modals/partnersModal';
import loader from '@/components/loader';
import userApi from '@/api/user/userApi';
import UBTable from '@/components/UBTable/UBTable';
import countriesApi from '@/api/countries/countriesApi';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import {
  DEPARTMENT_HEAD, MANAGER,
} from '@/constants/roleType';

import {
  UB_CLIENT,
} from '@/constants/listStaffDepartments';

export default {
  name: 'Partners',
  components: {
    partnersModal,
    adminPageHeader,
    loader,
    UBTable,
    UBPagination,
  },
  data() {
    return {
      partnerListDef: [],
      loader: false,
      columns: listSettingsPartners,
      fullInfo: {},
      isVisiblePartnerModal: false,
      noData: {
        status: false,
        title: this.$t('partnersPage.emptyPartnersPlaceholder.titleNoData'),
        description: this.$t('partnersPage.emptyPartnersPlaceholder.descriptionNoData'),
        buttonText: this.checkubClient ? this.$t('partnersPage.buttonText') : '',
        buttonOnClick: this.checkubClient ? this.showPartnerModal : false,
      },
      queryParams: {
        page: 1,
        perPage: 20,
        search: '',
        filter: '',
      },
      editPartner: {},
    };
  },
  computed: {
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    createdCountriesList() {
      return this.allCountriesList.filter((item) => item.isVisible);
    },
    userInfo: {
      get() {
        return this.$store.getters.userInfo;
      },
      set(data) {
        this.$store.dispatch('setUserInfo', data);
      },
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    userDepartment() {
      const { department = '' } = this.userInfo;
      return department;
    },
    checkubClient() {
      return (this.userRole === MANAGER || this.userRole === DEPARTMENT_HEAD) && this.userDepartment === UB_CLIENT;
    },
    headerPageOptions() {
      if (this.checkubClient) {
        return {
          title: this.$t('partnersPage.title'),
          search: {
            query: this.getSearchValue,
            value: this.queryParams.search,
          },
          filter: {
            options: this.countrySelectSettings,
            change: this.changeCountryFilter,
          },
        };
      }
      return {
        title: this.$t('partnersPage.title'),
        button: {
          text: this.$t('partnersPage.buttonText'),
          onClick: this.showPartnerModal,
        },
        search: {
          query: this.getSearchValue,
          value: this.queryParams.search,
        },
        filter: {
          options: this.countrySelectSettings,
          change: this.changeCountryFilter,
        },
      };
    },
    countrySelectSettings() {
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: this.queryParams.filter,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    selectCountriesList() {
      const arr = [];
      arr.push({
        key: '',
        text: this.$t('servicesPage.table.filter.allCountry'),
      });
      const sortedArr = sortBy(this.createdCountriesList, `name.${this.language}`);
      sortedArr.forEach((item) => {
        arr.push({
          text: item.name[this.language],
          key: item._id,
          image: `country/${item.code}.svg`,
        });
      });
      return arr;
    },
    partnerList: {
      get() {
        return this.partnerListDef;
      },
      set(data) {
        this.partnerListDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      search = this.queryParams.search,
      filter = this.queryParams.filter,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      search,
      filter,
    };
    if (this.allCountriesList.length === 0) {
      countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
      }).catch((err) => {
        console.error(err);
      });
    }
    this.getRequests(this.queryParams);
  },
  methods: {
    goToRoute(id) {
      this.$router.push({
        name: 'partnerForAdmin',
        params: {
          id,
        },
      });
    },
    preUpdateRequest() {
    },
    changeCountryFilter(filter) {
      this.queryParams = {
        ...this.queryParams,
        filter,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    getSearchValue(search) {
      this.queryParams = {
        ...this.queryParams,
        search,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getRequests(queryParams) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
        filter = this.queryParams.filter,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        search,
        filter,
      };
      const filterQuery = filter ? JSON.stringify({ country: filter }) : undefined;
      this.$router.push({
        query: {
          page,
          perPage,
          search: search || undefined,
          filter: filter || undefined,
        },
      }).catch(() => {});
      this.loader = true;
      userApi.getPartnersList({
        page, perPage, filter: filterQuery, search,
      })
        .then((resp) => {
          this.loader = false;
          const { totalDocs, resource } = resp.data;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({ totalDocs, page, perPage });
          this.partnerList = [];
          const arr = [];
          resource.forEach((item) => {
            const newArr = [];
            item.country.forEach((el) => {
              newArr.push({
                ...el,
                image: `country/${el.code}.svg`,
              });
            });
            arr.push(
              {
                ...item,
                country: newArr,
              },
            );
          });
          this.partnerList = arr;
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    removePartners(id) {
      userApi.deleteUser(id)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteRequest(partner) {
      const { row: { _id } } = partner;
      const { page, totalDocs } = this.fullInfo;
      const { perPage } = this.queryParams;
      const partnerIndex = findIndex(this.partnerList, (item) => item._id === _id);
      this.partnerList.splice(partnerIndex, 1);
      if (this.partnerList.length === 0) {
        await this.removePartners(_id);
        const newPage = page > 1 ? page - 1 : page;
        this.queryParams = {
          ...this.queryParams,
          page: newPage,
        };
        this.getRequests(this.queryParams);
      } else {
        this.getPaginationData({ totalDocs: totalDocs - 1, page, perPage });
        this.removePartners(_id);
      }
    },
    showPartnerModal() {
      this.isVisiblePartnerModal = true;
    },
    closeAddNewPartner() {
      this.isVisiblePartnerModal = false;
      this.editPartner = {};
    },
    openEditRequest(el) {
      this.editPartner = el.row;
      this.isVisiblePartnerModal = true;
    },
  },
};
</script>

<style scoped lang="scss">

.admin-partners-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
