<template>
  <section class="admin-clients-page">
    <loader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
    <UBTable
      :columns="columns"
      :rows="clientsList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 157px)'"
      :loader.sync="loader"
      :clickRow="true"
      @delete="deleteRequest"
      @clickByRow="goToRoute"
    />
    <UBPagination
      v-show="clientsList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
  </section>
</template>

<script>
import adminPageHeader from '@/components/adminPageHeader';
import loader from '@/components/loader';
import UBTable from '@/components/UBTable/UBTable';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import listSettingsClients from '@/constants/tablesSettings/listSettingsClients';
import queryString from 'query-string';
import userApi from '@/api/user/userApi';
import { findIndex } from 'lodash';
import countriesApi from '@/api/countries/countriesApi';

export default {
  name: 'Clients',
  components: {
    adminPageHeader,
    loader,
    UBTable,
    UBPagination,
  },
  data() {
    return {
      clientsListDef: [],
      loader: false,
      columns: listSettingsClients,
      fullInfo: {},
      noData: {
        status: false,
        title: this.$t('clientsPage.emptyClientsPlaceholder.titleNoData'),
      },
      queryParams: {
        page: 1,
        perPage: 20,
        search: '',
      },
    };
  },
  computed: {
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    headerPageOptions() {
      return {
        title: this.$t('clientsPage.title'),
        search: {
          query: this.getSearchValue,
          value: this.queryParams.search,
        },
      };
    },
    clientsList: {
      get() {
        return this.clientsListDef;
      },
      set(data) {
        this.clientsListDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      search = this.queryParams.search,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      search,
    };
    if (this.allCountriesList.length === 0) {
      countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
        return arr;
      }).catch((err) => {
        console.error(err);
      });
    }
    this.getRequests(this.queryParams);
  },
  methods: {
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    getSearchValue(search) {
      this.queryParams = {
        ...this.queryParams,
        search,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getRequests(queryParams) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        search,
      };
      this.$router.push({
        query: {
          page,
          perPage,
          search: search || undefined,
        },
      }).catch(() => {});
      this.loader = true;
      userApi.getClientsList({
        page, perPage, search,
      })
        .then((resp) => {
          this.loader = false;
          const { totalDocs, resource } = resp.data;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({ totalDocs, page, perPage });
          this.clientsList = resource;
          const arr = [];
          resource.forEach((item) => {
            const countryInfo = this.allCountriesList.find((country) => country.code === item.clientCountry);
            const {
              firstName,
              lastName = '',
              // eslint-disable-next-line global-require
              userAvatarUrl = require('@/assets/images/placeholder/avatar.svg'),
            } = item;
            const fullName = `${firstName} ${lastName}`;
            arr.push(
              {
                ...item,
                fullName,
                userAvatarUrl,
                country: countryInfo ? {
                  ...countryInfo,
                  image: this.getOptionImage(countryInfo.code) || '',
                } : [],
              },
            );
          });
          this.clientsList = arr;
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    removeClient(id) {
      userApi.deleteUser(id)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteRequest(client) {
      const { row: { _id } } = client;
      const { page, totalDocs } = this.fullInfo;
      const { perPage } = this.queryParams;
      const clientIndex = findIndex(this.clientsList, (item) => item._id === _id);
      this.clientsList.splice(clientIndex, 1);
      if (this.clientsList.length === 0) {
        await this.removeClient(_id);
        const newPage = page > 1 ? page - 1 : page;
        this.queryParams = {
          ...this.queryParams,
          page: newPage,
        };
        this.getRequests(this.queryParams);
      } else {
        this.getPaginationData({ totalDocs: totalDocs - 1, page, perPage });
        this.removeClient(_id);
      }
    },
    goToRoute(id) {
      const client = this.clientsList.find((item) => item._id === id);
      const { _id: clientId } = client;
      this.$router.push({
        name: 'client',
        params: {
          id: clientId,
          prevRoute: 'clients',
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
