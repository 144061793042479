import { uniqueId } from 'lodash';
import i18n from '@/i18n';

const columns = [
  {
    title: 'paymentsPage.table.client',
    type: 'image',
    prop: 'fullName',
    displayTitle: 'fullName',
    display: 'userAvatarUrl',
    imageStyle: {
      padding: 2,
      background: '#276EF1',
      width: 36,
      height: 36,
    },
    width: 'minmax(180px, 1fr)',
    defaultIcon: 'user',
  },
  {
    title: 'paymentsPage.table.numberOfOrder',
    prop: 'orderId.orderId',
    width: 'minmax(70px, 1fr)',
    route: 'adminOrder',
    field: 'orderId._id',
    sortBy: 'orderId',
  },
  {
    title: 'paymentsPage.table.numberOfPayment',
    prop: 'paypalOrderId',
    width: 'minmax(70px, 1fr)',
    sortBy: 'paypalOrderId',
  },
  {
    title: 'paymentsPage.table.dateOfPayment',
    prop: 'updatedAt',
    type: 'date',
    width: 'minmax(70px, 1fr)',
    sortBy: 'updatedAt',
    justifyContent: 'center',
  },
  {
    title: 'chequeModal.currency',
    prop: 'sum',
    width: 'minmax(70px, 1fr)',
    prefix: 'currency',
    justifyContent: 'center',
  },
  {
    title: 'refundModal.refund',
    prop: 'isRefunded',
    width: 'minmax(70px, 1fr)',
    isRefunded: 'isRefunded',
    icon: 'ub-icon-return',
    justifyContent: 'center',
    sortBy: 'isRefunded',
  },
  {
    title: 'paymentsPage.table.dateOfRefund',
    prop: 'refundDate',
    width: 'minmax(70px, 1fr)',
    type: 'date',
    sortBy: 'refundDate',
  },
  {
    type: 'toolbar',
    prop: `name.${i18n.locale}`,
    width: '26px',
    modalAction: true,
    prefix: 'currency',
  },
];

const listSettingsPayments = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsPayments;
