<template>
  <section class="admin-staffs-page">
    <loader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
    <UBTable
      :columns="columns"
      :rows="staffList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 157px)'"
      :loader.sync="loader"
      @update="preUpdateRequest"
      @delete="deleteRequest"
      @edit="openEditRequest"
      @sort="getSortedPaginationData"
    />
    <UBPagination
      v-show="staffList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
    <transition name="fade-el">
      <StaffsModal
        v-if="isVisibleStaffModal"
        :staffList="staffList"
        :editStaff="editStaff"
        @closeAddNewStaff="closeAddNewStaff"
      />
    </transition>
  </section>
</template>

<script>
import listSettingsStaffs from '@/constants/tablesSettings/listSettingsStaffs';
import staffRolesWithDepartment from '@/constants/staffRolesWithDepartment';
import queryString from 'query-string';
import { findIndex } from 'lodash';
import adminPageHeader from '@/components/adminPageHeader';
import StaffsModal from '@/components/modals/staffsModal';
import loader from '@/components/loader';
import userApi from '@/api/user/userApi';
import UBTable from '@/components/UBTable/UBTable';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import {
  DEPARTMENT_HEAD, OWNER, CEO, MANAGER,
} from '@/constants/roleType';
import {
  UB_AGENTS,
  UB_BUSINESS_DEVELOPMENT,
  UB_CLIENT,
  UB_CONTACT,
  UB_CONTENT, UB_INNOVATIONS,
  UB_SERVICES,
} from '@/constants/listStaffDepartments';

export default {
  name: 'Staffs',
  components: {
    StaffsModal,
    adminPageHeader,
    loader,
    UBTable,
    UBPagination,
  },
  data() {
    return {
      staffListDef: [],
      orderStatusList: [],
      loader: false,
      columns: listSettingsStaffs,
      fullInfo: {},
      isVisibleStaffModal: false,
      noData: {
        status: false,
        title: this.$t('staffPage.emptyStaffPlaceholder.titleNoData'),
        description: this.$t('staffPage.emptyStaffPlaceholder.descriptionNoData'),
        buttonText: this.$t('staffPage.buttonText'),
        buttonOnClick: this.showPartnerModal,
      },
      queryParams: {
        page: 1,
        perPage: 20,
        search: '',
      },
      editStaff: {},
    };
  },
  computed: {
    rolesSelectSettings() {
      return {
        optionList: this.selectRolesList,
        placeholderText: this.$t('staffPage.placeholder'),
        selectedKey: this.queryParams.filter,
        disable: false,
        toTop: false,
        tabindex: 1,
        readOnly: true,
      };
    },
    selectRolesList() {
      const arr = [];
      arr.push({
        key: '',
        text: this.$t('staffPage.allStaff'),
      });
      staffRolesWithDepartment.forEach((item) => {
        arr.push({
          text: item.text,
          key: item.key,
        });
      });
      return arr;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    userRole() {
      const { role = '' } = this.userInfo;
      return role;
    },
    headerPageOptions() {
      const isOwnerOrCEO = this.userRole === OWNER || this.userRole === CEO;
      return {
        title: this.$t('staffPage.title'),
        filter: isOwnerOrCEO ? {
          options: this.rolesSelectSettings,
          change: this.changeStatusFilter,
        } : undefined,
        button: {
          text: this.$t('staffPage.buttonText'),
          onClick: this.showPartnerModal,
        },
        search: {
          query: this.getSearchValue,
          value: this.queryParams.search,
        },
      };
    },
    staffList: {
      get() {
        return this.staffListDef;
      },
      set(data) {
        this.staffListDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      filter = this.queryParams.filter,
      search = this.queryParams.search,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      search,
      filter,
    };
    this.getRequests(this.queryParams);
  },
  methods: {
    changeStatusFilter(filter) {
      this.queryParams = {
        ...this.queryParams,
        filter,
        page: 1,
      };
      this.getRequests(this.queryParams, 'role');
    },
    getSortedPaginationData(data) {
      const { location } = window;
      const parsed = queryString.parse(location.search);
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        filter = this.queryParams.filter,
        search = this.queryParams.search,
      } = parsed;
      this.queryParams = {
        page: +page < 1 ? 1 : +page,
        perPage: +perPage < 1 ? 1 : +perPage,
        search,
        filter,
        sort: typeof data === 'string' ? {
          [`${data}`]: 1,
        } : data,
      };
      this.getRequests(this.queryParams);
    },
    preUpdateRequest() {
    },
    changeCountryFilter(filter) {
      this.queryParams = {
        ...this.queryParams,
        filter,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    getSearchValue(search) {
      this.queryParams = {
        ...this.queryParams,
        search,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getRequests(queryParams) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
        filter = this.queryParams.filter,
        sort = this.queryParams.sort,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        search,
        filter,
        sort,
      };
      const filterParams = filter || '';
      const arr = filterParams.split('-');
      const [role, department] = arr;
      const filterQuery = filterParams ? JSON.stringify({ role, department }) : undefined;
      this.$router.push({
        query: {
          page,
          perPage,
          search: search || undefined,
          filter: filter || undefined,
          sort: JSON.stringify(sort) || undefined,
        },
      }).catch(() => {});
      this.loader = true;
      userApi.getStaffsList({
        page, perPage, search, sort, filter: filterQuery,
      })
        .then((resp) => {
          this.loader = false;
          const { totalDocs, resource } = resp.data;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({ totalDocs, page, perPage });
          this.staffList = [];
          const arr = [];
          resource.forEach((item) => {
            const {
              firstName,
              lastName = '',
              userAvatarUrl = '',
            } = item;
            const fullName = `${firstName} ${lastName}`;
            const { role, department } = item;
            let translatedRoles = '';
            switch (item.role) {
            case OWNER:
              translatedRoles = this.$t('roles.owner');
              break;
            case MANAGER:
              if (item.department === UB_BUSINESS_DEVELOPMENT) {
                translatedRoles = this.$t('staffBusinessDevelopmentRoles.manager');
              }
              if (item.department === UB_CONTACT) {
                translatedRoles = this.$t('staffSalesRoles.manager');
              }
              if (item.department === UB_CLIENT) {
                translatedRoles = this.$t('staffCustomerServiceRoles.manager');
              }
              if (item.department === UB_CONTENT) {
                translatedRoles = this.$t('staffContentRoles.manager');
              }
              if (department === UB_INNOVATIONS) {
                translatedRoles = this.$t('staffInnovationsRoles.manager');
              }
              break;
            case CEO:
              translatedRoles = 'CEO';
              break;
            case DEPARTMENT_HEAD:
              if (department === UB_BUSINESS_DEVELOPMENT) {
                translatedRoles = this.$t('staffBusinessDevelopmentRoles.departmentHead');
              }
              if (department === UB_CONTACT) {
                translatedRoles = this.$t('staffSalesRoles.departmentHead');
              }
              if (department === UB_CLIENT) {
                translatedRoles = this.$t('staffCustomerServiceRoles.departmentHead');
              }
              break;
            default:
              break;
            }
            let translatedDepartment = '';
            switch (department) {
            case UB_CONTACT:
              translatedDepartment = this.$t('staffDepartments.contact');
              break;
            case UB_CLIENT:
              translatedDepartment = this.$t('staffDepartments.client');
              break;
            case UB_SERVICES:
              translatedDepartment = this.$t('staffDepartments.services');
              break;
            case UB_AGENTS:
              translatedDepartment = this.$t('staffDepartments.agents');
              break;
            case UB_BUSINESS_DEVELOPMENT:
              translatedDepartment = this.$t('staffDepartments.businessDevelopment');
              break;
            case UB_CONTENT:
              translatedDepartment = this.$t('staffDepartments.content');
              break;
            case UB_INNOVATIONS:
              translatedDepartment = this.$t('staffDepartments.innovations');
              break;
            default:
              break;
            }
            arr.push(
              {
                ...item,
                fullName,
                role,
                department: item.role === OWNER || item.role === CEO ? undefined : department,
                userAvatarUrl,
                translatedRoles,
                translatedDepartment,
              },
            );
          });
          this.staffList = arr;
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    removePartners(id) {
      userApi.deleteUser(id)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteRequest(staff) {
      const { row: { _id } } = staff;
      const { page, totalDocs } = this.fullInfo;
      const { perPage } = this.queryParams;
      const staffIndex = findIndex(this.staffList, (item) => item._id === _id);
      this.staffList.splice(staffIndex, 1);
      if (this.staffList.length === 0) {
        await this.removePartners(_id);
        const newPage = page > 1 ? page - 1 : page;
        this.queryParams = {
          ...this.queryParams,
          page: newPage,
        };
        this.getRequests(this.queryParams);
      } else {
        this.getPaginationData({ totalDocs: totalDocs - 1, page, perPage });
        this.removePartners(_id);
      }
    },
    showPartnerModal() {
      this.isVisibleStaffModal = true;
    },
    closeAddNewStaff() {
      this.isVisibleStaffModal = false;
      this.editStaff = {};
    },
    openEditRequest(el) {
      this.editStaff = el.row;
      this.isVisibleStaffModal = true;
    },
  },
};
</script>

<style scoped>

</style>
