import i18n from '@/i18n';

import {
  UB_CONTACT,
  UB_CLIENT,
  // UB_SERVICES,
  UB_AGENTS,
  UB_BUSINESS_DEVELOPMENT,
  UB_CONTENT,
  UB_INNOVATIONS,
  UB_OUTSIDE_DEPARTMENT,
} from '@/constants/listStaffDepartments';

const staffDepartments = [
  {
    key: UB_OUTSIDE_DEPARTMENT,
    text: i18n.t('staffDepartments.outside'),
  },
  {
    key: UB_CONTACT,
    text: i18n.t('staffDepartments.contact'),
  },
  {
    key: UB_CLIENT,
    text: i18n.t('staffDepartments.client'),
  },
  {
    key: UB_BUSINESS_DEVELOPMENT,
    text: i18n.t('staffDepartments.businessDevelopment'),
  },
  {
    key: UB_AGENTS,
    text: i18n.t('staffDepartments.agents'),
  },
  // {
  //   key: UB_SERVICES,
  //   text: i18n.t('staffDepartments.services'),
  // },
  {
    key: UB_CONTENT,
    text: i18n.t('staffDepartments.content'),
  },
  {
    key: UB_INNOVATIONS,
    text: i18n.t('staffDepartments.innovations'),
  },
];
export default staffDepartments;
