import i18n from '@/i18n';

import {
  MANAGER,
  DEPARTMENT_HEAD,
} from '@/constants/listStaffRoles';

const staffSalesRoles = [
  {
    key: DEPARTMENT_HEAD,
    text: i18n.t('staffSalesRoles.departmentHead'),
  },
  {
    key: MANAGER,
    text: i18n.t('staffSalesRoles.manager'),
  },
];
export default staffSalesRoles;
