import { uniqueId } from 'lodash';
import i18n from '@/i18n';

const columns = [
  {
    title: 'clientsPage.table.columns.name',
    type: 'image',
    prop: 'fullName',
    displayTitle: 'fullName',
    display: 'userAvatarUrl',
    imageStyle: {
      padding: 2,
      background: '#276EF1',
      width: 36,
      height: 36,
    },
    width: 'minmax(180px, 1fr)',
    defaultIcon: 'user',
  },
  {
    title: 'servicesPage.table.columns.country',
    pointer: true,
    type: 'image',
    locale: true,
    prop: `country.name.${i18n.locale}`,
    displayTitle: `country.name.${i18n.locale}`,
    display: 'country.image',
    width: 'minmax(70px, 1fr)',
  },
  {
    title: 'clientsPage.table.columns.email',
    prop: 'email',
    width: 'minmax(300px, 0.8fr)',
    link: true,
    linkHref: 'email',
    linkOption: 'mailto',
  },
  {
    title: 'clientsPage.table.columns.phone',
    prop: 'phone',
    width: 'minmax(70px, 0.8fr)',
  },
  {
    title: 'clientsPage.table.columns.amountOfDeals',
    width: 'minmax(70px, 0.7fr)',
    prop: 'ordersCount',
    justifyContent: 'center',
  },
  // {
  //   title: 'clientsPage.table.columns.amountOfTransactions',
  //   pointer: true,
  //   width: 'minmax(70px, 1.1fr)',
  //   prop: 'ordersSum',
  // },
  // {
  //   type: 'toolbar',
  //   prop: 'name',
  //   width: '26px',
  // },
];

const listSettingsClients = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsClients;
