export const IT = 'IT';
export const HOTELS = 'HOTELS';
export const RESTAURANTS = 'RESTAURANTS';
export const WHOLESALE = 'WHOLESALE';
export const FRANCHISE = 'FRANCHISE';
export const INSURANCE = 'INSURANCE';
export const PAYMENT_SYSTEMS = 'PAYMENT_SYSTEMS';
export const RETAIL = 'RETAIL';
export const GAMBLE = 'GAMBLE';
export const DEALERSHIP_AND_BROKING = 'DEALERSHIP_AND_BROKING';
export const ADVISORY_SERVICES = 'ADVISORY_SERVICES';
export const CONSTRUCTION = 'CONSTRUCTION';
export const TRANSPOURT_AND_LOGISTICS = 'TRANSPOURT_AND_LOGISTICS';
export const LEASE = 'LEASE';
export const MINERALS_EXTRACTION = 'MINERALS_EXTRACTION';
export const AGRICULTURE = 'AGRICULTURE';
export const MANUFACTURE = 'MANUFACTURE';
export const OTHER = 'OTHER';
