import { uniqueId } from 'lodash';
import i18n from '@/i18n';

const columns = [
  {
    title: 'clientsPage.table.columns.name',
    type: 'image',
    prop: 'fullName',
    displayTitle: 'fullName',
    display: 'userAvatarUrl',
    imageStyle: {
      padding: 2,
      background: '#276EF1',
      width: 36,
      height: 36,
    },
    width: 'minmax(180px, 1fr)',
    defaultIcon: 'user',
  },
  {
    title: 'staffPage.table.birthday',
    prop: 'birthday',
    type: 'date',
    width: 'minmax(70px, 1fr)',
  },
  {
    title: 'staffModal.department',
    prop: 'translatedDepartment',
    width: 'minmax(70px, 1fr)',
    displayTitle: 'translatedDepartment',
    sortBy: 'department',
  },
  {
    title: 'staffPage.table.role',
    prop: 'translatedRoles',
    width: 'minmax(70px, 1fr)',
    displayTitle: 'translatedRoles',
    sortBy: 'role',
  },
  {
    type: 'toolbar',
    prop: `name.${i18n.locale}`,
    width: '26px',
  },
];

const listSettingsStaffs = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsStaffs;
