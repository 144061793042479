import i18n from '@/i18n';
import getUniqueId from '@/mixins/uniqueId';
import {
  SUB_TYPE_RADIO,
  SUB_TYPE_HEADLINE,
  SUB_TYPE_ACCORDEON,
  SUB_TYPE_CHECKBOX,
  SUB_TYPE_UPLOAD_FILE,
  SUB_TYPE_DESCRIPTION,
} from '@/preset/elements/listSubTypes';

const elementList = [
  {
    id: getUniqueId(),
    image: 'ub-icon-headline',
    name: i18n.t('elements.heading'),
    sub: SUB_TYPE_HEADLINE,
  },
  {
    id: getUniqueId(),
    image: 'ub-icon-description',
    name: i18n.t('elements.text'),
    sub: SUB_TYPE_DESCRIPTION,
  },
  {
    id: getUniqueId(),
    image: 'ub-icon-radio',
    name: i18n.t('elements.radiobutton'),
    sub: SUB_TYPE_RADIO,
  },
  {
    id: getUniqueId(),
    image: 'ub-icon-checkbox',
    name: i18n.t('elements.checkbox'),
    sub: SUB_TYPE_CHECKBOX,
  },
  {
    id: getUniqueId(),
    image: 'ub-icon-accordion',
    name: i18n.t('elements.accordeon'),
    sub: SUB_TYPE_ACCORDEON,
  },
  {
    id: getUniqueId(),
    image: 'ub-icon-file',
    name: i18n.t('elements.file'),
    sub: SUB_TYPE_UPLOAD_FILE,
  },
];

export default elementList;
