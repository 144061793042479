import i18n from '@/i18n';

import {
  CEO,
  OWNER,
} from '@/constants/listStaffRoles';

const staffOutsideDepartmentRoles = [
  {
    key: OWNER,
    text: i18n.t('staffRoles.owner'),
    role: OWNER,
  },
  {
    key: CEO,
    text: i18n.t('staffRoles.ceo'),
  },
];
export default staffOutsideDepartmentRoles;
