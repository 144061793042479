<template>
  <div class="admin-modal">
    <loader v-if="loader" />
    <div class="admin-modal-wrap">
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          <template v-if="!Object.keys(editPartner).length">
            {{ $t('partnersPage.buttonText') }}
          </template>
          <template v-else>
            {{ $t('partnersPage.editTitle') }}
          </template>
        </div>
        <div
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </div>
      </div>
      <div class="admin-modal-wrap-content">
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('requestCallModal.denomination') }}
          </div>
          <textInput
            :typeInput="'text'"
            :autofocus="true"
            :value.sync="currentPartner.partnerCompanyName"
            :class="{'error': errors.has('partnerCompanyName')}"
            :errorStatus="errors.has('partnerCompanyName')"
            :textCapitalize="true"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('partnerCompanyName')"
              class="validation"
            >
              {{ errors.first('partnerCompanyName') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('requestCallModal.contactPerson') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="currentPartner.contactPerson"
            :class="{'error': errors.has('contactPerson')}"
            :errorStatus="errors.has('contactPerson')"
            :textCapitalize="true"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('contactPerson')"
              class="validation"
            >
              {{ errors.first('contactPerson') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('partnersPage.table.columns.country') }}
          </div>
          <multipleSelectTemplate
            :settings.sync="selectSettings"
            :class="{'error': errors.has('country')}"
            @changeSelect="changeSelect($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('country')"
              class="validation"
            >
              {{ errors.first('country') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('partnersPage.table.columns.phone') }}
          </div>
          <VueTelInput
            v-model="currentPartner.phone"
            name="phone"
            :required="required"
            :validCharactersOnly="validCharactersOnly"
            :inputOptions="inputOptions"
            :placeholder="placeholder"
            :maxLen="maxLen"
            :class="{'error': $validator.errors.has('phone')}"
            :errorStatus="$validator.errors.has('phone')"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('phone')"
              class="validation"
            >
              {{ errors.first('phone') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('partnersPage.email') }}
          </div>
          <textInput
            :typeInput="'email'"
            :value.sync="currentPartner.email"
            :class="{'error': $validator.errors.has('email')}"
            :errorStatus="$validator.errors.has('email')"
          >
          </textInput>
          <transition name="fade-content">
            <span
              v-show="$validator.errors.has('email')"
              class="validation"
            >
              {{ $validator.errors.first('email') }}
            </span>
          </transition>
          <transition name="fade-content">
            <div
              v-if="validationError.status"
              class="validation"
            >
              {{ validationError.text }}
            </div>
          </transition>
        </div>
      </div>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            @click="addNewPartner"
          >
            <template v-if="editPartnerStatus">
              {{ $t('buttons.save') }}
            </template>
            <template v-else>
              {{ $t('buttons.add') }}
            </template>
          </button>
        </div>
      </div>
    </div>
    <div
      v-shortkey="['esc']"
      class="admin-modal__overlay"
      @click="closeModal"
      @shortkey="closeModal"
    ></div>
  </div>
</template>

<script>
import multipleSelectTemplate from '@/components/fields/multipleSelectTemplate';
import textInput from '@/components/fields/textInput';
import userApi from '@/api/user/userApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import generator from 'generate-password';
import { VueTelInput } from 'vue-tel-input';
import countriesApi from '@/api/countries/countriesApi';
import { findIndex, sortBy } from 'lodash';
import loader from '@/components/loader';
import { UB_SERVICES } from '@/constants/listStaffDepartments';

export default {
  name: 'PartnersModal',
  components: {
    textInput,
    VueTelInput,
    multipleSelectTemplate,
    loader,
  },
  props: {
    partnerList: {
      type: Array,
      default: () => [],
    },
    editPartner: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loader: false,
      validationError: {
        text: '',
        status: false,
      },
      isVisiblePassword: false,
      editPartnerStatus: false,
      required: true,
      validCharactersOnly: true,
      maxLen: 17,
      placeholder: '',
      currentPartner: {
        partnerCompanyName: '',
        contactPerson: '',
        department: UB_SERVICES,
        country: [],
        phone: '',
        email: '',
        role: 'Partner',
        password: generator.generate({
          length: 10,
          numbers: true,
        }),
      },
    };
  },
  computed: {
    inputOptions() {
      return {
        required: true,
        mode: 'national',
        enabledFlags: false,
      };
    },
    selectSettings() {
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: this.currentPartner.country,
        disable: false,
        toTop: false,
        tabindex: 1,
        readOnly: false,
        selectClass: 'select_alt',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    language() {
      return this.$i18n.locale;
    },
    createdCountriesList() {
      return this.allCountriesList.filter((item) => item.isVisible);
    },
    selectCountriesList() {
      const newArr = [];
      const sortedArr = sortBy(this.createdCountriesList, `name.${this.language}`);
      sortedArr.forEach((item) => {
        newArr.push({
          text: item.name[this.language],
          key: item._id,
          image: `country/${item.code}.svg`,
        });
      });
      return newArr;
    },
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          partnerCompanyName: {
            required: validationErrorMessage.en.inputRequired,
          },
          contactPerson: {
            required: validationErrorMessage.en.inputRequired,
          },
          country: {
            required: validationErrorMessage.en.inputRequired,
          },
          phone: {
            required: validationErrorMessage.en.inputPhone,
          },
          email: {
            required: validationErrorMessage.en.inputRequired,
            email: validationErrorMessage.en.inputEmail,
          },
        },
      },
      ru: {
        custom: {
          partnerCompanyName: {
            required: validationErrorMessage.ru.inputRequired,
          },
          contactPerson: {
            required: validationErrorMessage.ru.inputRequired,
          },
          country: {
            required: validationErrorMessage.ru.inputRequired,
          },
          phone: {
            required: validationErrorMessage.ru.inputPhone,
          },
          email: {
            required: validationErrorMessage.ru.inputRequired,
            default: validationErrorMessage.ru.inputEmail,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'partnerCompanyName', rules: { required: true } });
    this.$validator.attach({ name: 'contactPerson', rules: { required: true } });
    this.$validator.attach({ name: 'country', rules: { required: true } });
    this.$validator.attach({ name: 'phone', rules: { required: true } });
    this.$validator.attach({
      name: 'email',
      rules: {
        required: true,
        // eslint-disable-next-line max-len
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    });
    if (this.allCountriesList.length === 0) {
      countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
        return arr;
      }).catch((err) => {
        console.error(err);
      });
    }
    if (Object.keys(this.editPartner).length) {
      this.currentPartner = {
        ...this.editPartner,
        partnerCompanyName: this.editPartner.partnerCompanyName,
        contactPerson: this.editPartner.contactPerson,
        country: this.editPartner.country.map((item) => item._id),
        phone: this.editPartner.phone,
        email: this.editPartner.email,
      };
      this.editPartnerStatus = true;
    } else {
      this.editPartnerStatus = false;
    }
  },
  methods: {
    addServiceQuery() {
      this.loader = true;
      userApi.createPartner(this.currentPartner).then((resp) => {
        const arr = [];
        resp.data.country.forEach((el) => {
          arr.push({
            ...el,
            image: `country/${el.code}.svg`,
          });
        });
        this.partnerList.unshift({
          ...resp.data,
          country: arr,
          ordersCount: 0,
        });
        this.loader = false;
        this.$emit('closeAddNewPartner');
      })
        .catch((err) => {
          this.loader = false;
          const str = err.response.data.message;
          if (err.response && (str.includes('User already exists'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationText'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          if (err.response && (str.includes('Please fill a valid email address'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationTextEmail'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          console.error(err);
        });
    },
    saveServiceQuery() {
      const partner = {
        ...this.currentPartner,
        fullName: undefined,
      };
      this.loader = true;
      userApi.updatePartner(partner)
        .then((resp) => {
          this.loader = false;
          const arr = [];
          resp.data.country.forEach((el) => {
            arr.push({
              ...el,
              image: `country/${el.code}.svg`,
            });
          });
          const currentPartnerIndex = findIndex(this.partnerList, (
            (partner) => partner._id === this.editPartner._id));
          this.partnerList.splice(currentPartnerIndex, 1, {
            ...resp.data,
            fullName: `${resp.data.partnerCompanyName}`,
            country: arr,
          });
          this.$emit('closeAddNewPartner');
        })
        .catch((err) => {
          this.loader = false;
          const str = err.response.data.message;
          if (err.response && (str.includes('duplicate key error'))) {
            this.validationError = {
              status: true,
              text: this.$t('partnersPage.validationText'),
            };
            setTimeout(() => {
              this.validationError = {
                status: false,
                text: '',
              };
            }, 3000);
          }
          console.error(err);
        });
    },
    removeValidationError(error) {
      if (this.$validator.errors && this.$validator.errors.items) {
        const fieldError = this.$validator.errors.items.find((item) => item.field === error);
        if (fieldError) {
          this.$validator.errors.removeById(fieldError.id);
        }
      }
    },
    showPassword() {
      this.isVisiblePassword = !this.isVisiblePassword;
    },
    closeModal() {
      this.$emit('closeAddNewPartner');
    },
    addNewPartner() {
      this.$validator.validateAll({
        partnerCompanyName: this.currentPartner.partnerCompanyName,
        contactPerson: this.currentPartner.contactPerson,
        country: this.currentPartner.country,
        phone: (this.currentPartner.phone.length <= this.maxLen && this.currentPartner.phone.length >= 11)
          ? this.currentPartner.phone : '',
        email: this.currentPartner.email,
      }).then((result) => {
        if (result) {
          if (this.editPartnerStatus) {
            this.saveServiceQuery();
          } else {
            this.addServiceQuery();
          }
        }
      });
    },
    changeSelect(key) {
      this.currentPartner.country = key;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.admin-modal-wrap {
  width: 400px;
}

</style>
