<template>
  <div>
    <div
      class="action-element-group"
    >
      <div class="action-element-group-header">
        <div class="action-element-group-header__title">
          {{ $t('groupRadioModal.title') }}
        </div>
      </div>
      <div class="action-element-group-content">
        <div class="action-element-group-content-list">
          <div
            v-for="group in uniqueRadioGroup"
            :key="group"
            class="action-element-group-content-list__item"
            @click="setRadioGroup(group)"
          >
            {{ group }}
            <i
              v-if="item.groupRadio === group"
              class="ub-icon-check-mark-select"
              @click="removeGroup()"
            ></i>
          </div>
        </div>
        <div
          v-if="isVisibleNewField"
          class="action-element-group-content-list__create"
        >
          <textInput
            :typeInput="'text'"
            :class="{'error': $validator.errors.has('groupText')}"
            :errorStatus="$validator.errors.has('groupText')"
            :autofocus="true"
            :value.sync="groupText"
          />
          <transition name="fade-el">
            <span
              v-if="$validator.errors.has('groupText')"
              class="validation"
            >
              {{ $validator.errors.first('groupText') }}
            </span>
          </transition>
        </div>
        <div class="action-element-group-content__new">
          <i class="ub-icon-cross"></i>
          <span @click="showNewField">
            {{ $t('groupRadioModal.button') }}
          </span>
        </div>
      </div>
      <div class="action-element-group-footer">
        <button
          class="ub-btn ub-btn_cancel"
          @click="closeModal"
        >
          {{ $t('global.cancel') }}
        </button>
        <button
          class="ub-btn ub-btn_primary"
          @click="addNewGroup"
        >
          {{ $t('buttons.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import validationErrorMessage from '@/locales/validationErrorMessage';

export default {
  name: 'GroupRadioButtonModal',
  components: {
    textInput,
  },
  props: {
    el: {
      type: Object,
      default: () => {},
    },
    uniqueRadioGroup: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      groupText: '',
      isVisibleNewField: false,
      activeGroup: '',
    };
  },
  computed: {
    item: {
      get() {
        return this.el;
      },
      set(data) {
        this.$emit('update:el', data);
      },
    },
  },
  beforeMount() {
    const dict = {
      en: {
        custom: {
          groupText: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        custom: {
          groupText: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'groupText', rules: { required: true } });
  },
  methods: {
    removeGroup() {
      this.$emit('updateRadioGroup', this.item, '');
    },
    closeModal() {
      this.$emit('groupRadioModal', false);
    },
    setRadioGroup(group) {
      this.$emit('updateRadioGroup', this.item, group);
    },
    addNewGroup() {
      if (this.isVisibleNewField) {
        this.$validator.validateAll({
          groupText: this.groupText,
        }).then((result) => {
          if (result) {
            this.$emit('updateRadioGroup', this.item, this.groupText);
            this.groupText = '';
            this.isVisibleNewField = false;
          }
        });
      } else {
        this.closeModal();
      }
    },
    showNewField() {
      this.isVisibleNewField = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/sass/variables";

.action-element-group {
  width: 360px;
  background: $color-white;
  padding: 12px 0;
  font-size: $font-size-base;
  position: absolute;
  right: 31px;
  top: 33px;
  border-radius: $borderRadius;
  box-shadow: 0 0 10px rgba($color-black, .1);

  &-header {
    &__title {
      text-align: center;
      font-family: $font-global-medium;
      margin-bottom: 5px;
    }
  }

  &-content {

    &__new {
      padding: 12px;
      color: $color-dodger-blue;

      span {
        cursor: pointer;
        transition: color .15s ease-in;

        &:hover {
          color: $color-cornflower-blue;
        }
      }

      i {
        font-size: $font-size-xlg;
      }
    }

    &-list {

      &__create {
        padding: 10px 12px;
        position: relative;
        height: 68px;
        border-bottom: 1px solid $color-alabaster;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 100%;

        .text-field {
          width: 100%;
        }
      }

      &__item {
        min-height: 60px;
        padding: 19px 12px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-alabaster;
        position: relative;
        cursor: pointer;
        transition: background-color .15s ease-in;

        &:hover {
          background-color: $color-alabaster;
        }

        i {
          font-size: $font-size-xlg;
          color: $color-dodger-blue;
          position: absolute;
          right: 10px;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 13px 12px 0;

    button + button {
      margin-left: 5px;
    }
  }

  .validation {
    bottom: -2px;
    left: 12px;
  }
}

</style>
