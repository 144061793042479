import i18n from '@/i18n';

const typeFieldsListModal = [
  {
    text: i18n.t('typeFieldsListModal.text'),
    key: 'text',
  },
  {
    text: i18n.t('typeFieldsListModal.range'),
    key: 'range',
  },
  {
    text: i18n.t('typeFieldsListModal.short'),
    key: 'short',
  },
  {
    text: i18n.t('typeFieldsListModal.optional'),
    key: 'optional',
  },
];
export default typeFieldsListModal;
