import i18n from '@/i18n';

import {
  MANAGER,
  DEPARTMENT_HEAD,
} from '@/constants/listStaffRoles';

const staffBusinessDevelopmentRoles = [
  {
    key: DEPARTMENT_HEAD,
    text: i18n.t('staffBusinessDevelopmentRoles.departmentHead'),
  },
  {
    key: MANAGER,
    text: i18n.t('staffBusinessDevelopmentRoles.manager'),
  },
];
export default staffBusinessDevelopmentRoles;
