<template>
  <transition
    name="fade-content"
    mode="out-in"
  >
    <VuePerfectScrollbar
      class="steps-settings"
    >
      <div
        class="steps-settings__wrapper"
      >
        <div
          class="steps-settings__row"
        >
          <div class="steps-settings__group">
            <textInput
              :labelText="stepsSettings.labelStep"
              :class="{'error': $validator.errors.has('titleStep')}"
              :errorStatus="$validator.errors.has('titleStep')"
              :typeInput="'text'"
              :autofocus="true"
              :value.sync="titleCurrentStep"
              :customClass="'text-field_364'"
            >
            </textInput>
            <transition name="fade-el">
              <span
                v-if="$validator.errors.has('titleStep')"
                class="validation"
              >
                {{ $validator.errors.first('titleStep') }}
              </span>
            </transition>
          </div>
          <div class="steps-settings__group">
            <textInput
              :labelText="stepsSettings.labelCost"
              :class="{'error': $validator.errors.has('cost')}"
              :errorStatus="$validator.errors.has('cost')"
              :typeInput="'number'"
              :value.sync="stepsSettings.cost"
              :customClass="'text-field_200'"
              :name="'cost'"
            >
            </textInput>
            <div
              v-if="!$validator.errors.has('cost')"
              class="steps-settings__icon"
              @click="showCurrencyModal"
            >
              <span>{{ currentService.currency }}</span>
            </div>
            <transition name="fade-el">
              <div
                v-if="false"
                class="select-modal"
              >
                <div class="select-modal__list">
                  <div
                    v-for="currency in currencyList"
                    :key="currency"
                    class="select-modal__item"
                    @click="selectCurrency(currency)"
                  >
                    {{ currency }}
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-el">
              <span
                v-if="$validator.errors.has('cost')"
                class="validation"
              >
                {{ $validator.errors.first('cost') }}
              </span>
            </transition>
          </div>
          <div class="steps-settings__group">
            <textInput
              :labelText="stepsSettings.labelTiming"
              :class="{'error': $validator.errors.has('timing')}"
              :errorStatus="$validator.errors.has('timing')"
              :typeInput="'number'"
              :value.sync="stepsSettings.timing"
              :customClass="'text-field_200'"
              :name="'timing'"
            >
            </textInput>
            <div
              v-if="!$validator.errors.has('timing')"
              class="steps-settings__text"
              @click="showTimingModal"
            >
              {{ $t('timingModal') }}
            </div>
            <transition name="fade-el">
              <div
                v-if="false"
                class="select-modal"
              >
                <div class="select-modal__list">
                  <div
                    v-for="timing in timingList"
                    :key="timing"
                    class="select-modal__item"
                    @click="selectTiming(timing)"
                  >
                    {{ timing }}
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade-el">
              <span
                v-if="$validator.errors.has('timing')"
                class="validation"
              >
                {{ $validator.errors.first('timing') }}
              </span>
            </transition>
          </div>
        </div>
        <div
          v-click-outside="clickOutside"
          class="open-window-elements open-window-elements_margin open-window-elements_width"
          :class="{'open-window-elements_active open-window-elements_biggest':
            openWindowElements === stepsSettings.stepId
            && elementListWindow !== -1}"
          @click="openListElement(stepsSettings.stepId)"
        >
          <div
            class="open-window-elements__icon"
            :class="{'open-window-elements_active open-window-elements_biggest':
              openWindowElements === stepsSettings.stepId}"
          >
            <i class="ub-icon-cross"></i>
          </div>
          <transition
            name="fade-el"
            mode="out-in"
          >
            <allElements
              v-if="openWindowElements === stepsSettings.stepId"
              :elementList="elementList"
              :stepId="stepsSettings.stepId"
              :allElementsProps="stepsSettings.fields"
              :allSteps="allSteps"
              :openWindowElementsDef.sync="openWindowElements"
              :indexElement="indexElement"
            />
          </transition>
        </div>
        <draggable
          v-model="stepsSettings.fields"
          class="dragArea"
          :options="{
            filter: '.action-elements, .open-window-elements',
            animation: 150,
            dropzoneSelector: '.drag-inner-list',
            draggableSelector: '.insert-element',
            handle: '.insert-element__icon',
            showDropzoneAreas: true,
            multipleDropzonesItemsDraggingEnabled: true
          }"
          @start="startMove"
          @end="onEnd"
        >
          <transition-group
            name="fade-el"
            tag="div"
            class="drag-inner-list"
          >
            <div
              v-for="(el, indexEl) in stepsSettings.fields"
              :id="el.fieldId"
              :key="el.fieldId"
              class="insert-element"
              :class="{'insert-element_active':
                toggleIconAction === el.fieldId
                || elementListWindow === el.fieldId
                || isVisibleGroupRadioModal === el.fieldId
                || openWindowElementsInside === el.fieldId
              }"
            >
              <div
                class="insert-element__icon"
              >
                <i
                  class="ub-icon-drag"
                ></i>
              </div>
              <ElementDefault
                :element.sync="el"
                :stepsField="true"
                :step="stepsSettings"
                :lang="lang"
                :fieldsError="fieldsError"
              />
              <div
                v-if="el.sub === 'SUB_TYPE_RADIO'"
                class="insert-element-radio"
              >
                <span
                  v-if="el.groupRadio !== ''"
                  class="insert-element-radio__text"
                >
                  {{ el.groupRadio }}
                </span>
                <span
                  v-else
                  class="insert-element-radio__text"
                >
                  {{ $t('groupRadioModal.group') }}
                </span>
                <i
                  class="ub-icon-connect"
                  @click="groupRadioModal(el.fieldId)"
                ></i>
              </div>
              <transition
                name="fade-el"
                mode="out-in"
              >
                <GroupRadioButtonModal
                  v-if="isVisibleGroupRadioModal === el.fieldId && el.sub === 'SUB_TYPE_RADIO'"
                  :el.sync="el"
                  :uniqueRadioGroup="uniqueRadioGroup"
                  @updateRadioGroup="updateRadioGroup"
                  @groupRadioModal="groupRadioModal"
                />
              </transition>
              <div
                :key="el.fieldId"
                class="open-window-elements"
                :class="{'open-window-elements_active open-window-elements_biggest':
                  openWindowElementsInside === el.fieldId
                }"
                @click="openListElementInside(el.fieldId, indexEl)"
              >
                <div class="open-window-elements__icon">
                  <i class="ub-icon-cross 2"></i>
                </div>
                <transition
                  name="fade-el"
                  mode="out-in"
                >
                  <allElements
                    v-if="openWindowElementsInside === el.fieldId"
                    :elementList="elementList"
                    :stepId="stepsSettings.stepId"
                    :allElementsProps.sync="stepsSettings.fields"
                    :openWindowElements.sync="openWindowElements"
                    :allSteps="allSteps"
                    :indexElement="indexElement"
                  />
                </transition>
              </div>
              <div
                class="action-elements"
                :class="toggleIconAction === el.fieldId ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
                @click.stop="openWindowSettings(el.fieldId)"
              >
                <transition
                  name="fade-el"
                  mode="out-in"
                >
                  <div
                    v-if="chooseWindow === el.fieldId"
                    v-click-outside="closeChooseWindow"
                    class="action-elements-choose"
                    :class="{'action-elements-choose_lg': showSelectParentField}"
                    @click.stop
                  >
                    <transition
                      name="fade-el"
                      mode="out-in"
                    >
                      <div
                        v-if="showSelectParentField"
                        class="action-elements-choose-select"
                      >
                        <div class="admin-form-field">
                          <div class="admin-form-field__label">
                            {{ $t('servicesPage.addServiceModal.selectControl') }}
                          </div>
                          <multipleSelectTemplate
                            :settings="parentSelectSettings"
                            @changeSelect="changeParentSelect"
                          />
                        </div>
                        <div class="admin-form-field admin-form-field_end">
                          <button
                            class="ub-btn ub-btn_primary"
                            @click="saveParentField"
                          >
                            {{ $t('buttons.save') }}
                          </button>
                        </div>
                      </div>
                      <div
                        v-else
                        class="action-elements-choose-controls"
                      >
                        <button
                          v-if="showSettings(el)"
                          class="ub-button action-elements-choose-controls__button"
                          @click.stop="settingsElFromStep(el)"
                        >
                          {{ $t('buttons.settings') }}
                        </button>
                        <button
                          class="ub-button action-elements-choose-controls__button"
                          @click.stop="removeElFromStep(el, indexEl)"
                        >
                          {{ $t('buttons.delete') }}
                        </button>
                      </div>
                    </transition>
                  </div>
                </transition>
              </div>
            </div>
          </transition-group>
        </draggable>
        <div class="steps-settings__footer">
          <div class="steps-settings__footer-checkbox">
            <checkBox
              :checkboxText="$t('servicesPage.requiredStep')"
              :value.sync="stepsSettings.isRequired"
              :customClass="'ub-checkbox_modal'"
            />
          </div>
          <div class="steps-settings__item">
            <button
              class="ub-btn ub-btn_cancel steps-settings__cancel"
              @click.stop="closeStepSetting()"
            >
              {{ $t('global.cancel') }}
            </button>
          </div>
          <div class="steps-settings__item">
            <button
              class="ub-btn ub-btn_primary"
              @click.stop="addToStepList()"
            >
              <template v-if="stepsSettings.newStep">
                {{ $t('buttons.add') }}
              </template>
              <template v-else>
                {{ $t('buttons.save') }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
  </transition>
</template>

<script>
import textInput from '@/components/fields/textInput';
import GroupRadioButtonModal from '@/components/modals/groupRadioButtonModal';
import ClickOutside from 'vue-click-outside';
import draggable from 'vuedraggable';
import ElementDefault from '@/components/elements/DefaultTemplate';
import validationErrorMessage from '@/locales/validationErrorMessage';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import allElements from '@/components/elements/allElements';
import elementListSteps from '@/constants/stepsSettings/listSettingsSteps';
import { SUB_TYPE_RADIO, SUB_TYPE_CHECKBOX } from '@/preset/elements/listSubTypes';
import checkBox from '@/components/fields/checkBox';
import {
  filter, findIndex, cloneDeep, uniq,
} from 'lodash';
import multipleSelectTemplate from '@/components/fields/multipleSelectTemplate';

export default {
  name: 'StepsSettings',
  components: {
    textInput,
    ElementDefault,
    allElements,
    VuePerfectScrollbar,
    multipleSelectTemplate,
    draggable,
    GroupRadioButtonModal,
    checkBox,
  },
  directives: {
    ClickOutside,
  },
  props: {
    currentStep: {
      type: Object,
    },
    currentService: {
      type: Object,
    },
    allSteps: {
      type: Object,
      default: () => {},
    },
    lang: {
      type: String,
      default: 'ru',
    },
  },
  data() {
    return {
      indexElement: -1,
      chooseWindow: -1,
      toggleIconAction: -1,
      openWindowElements: -1,
      elementListWindow: -1,
      openWindowElementsInside: -1,
      elementList: elementListSteps,
      parentComponentsList: [],
      setActiveCountry: -1,
      currentFieldSettings: {},
      showSelectParentField: false,
      selectOpenStatus: false,
      currencyList: ['USD'],
      timingList: ['days'],
      isVisibleCurrencyModal: false,
      isVisibleTimingModal: false,
      timingText: 'days',
      isVisibleGroupRadioModal: -1,
      fieldsError: [],
    };
  },
  computed: {
    uniqueRadioGroup() {
      const radioList = uniq(filter(this.stepsSettings.fields, (item) => item.sub === SUB_TYPE_RADIO
          && item.groupRadio !== '').map((item) => item.groupRadio));
      return radioList;
    },
    stepsSettings: {
      get() {
        return this.currentStep;
      },
      set(data) {
        this.$emit('update:currentStep', data);
      },
    },
    titleCurrentStep: {
      get() {
        return this.currentStep.titleStep;
      },
      set(data) {
        this.stepsSettings.titleStep = data;
      },
    },
    parentSelectSettings() {
      return {
        optionList: this.parentComponentsList,
        placeholderText: this.$t('servicesPage.addServiceModal.selectControl'),
        selectedKey: this.currentFieldSettings.parentId,
        disable: false,
        toTop: false,
        tabindex: 1,
      };
    },
  },
  watch: {
    'stepsSettings.cost': {
      deep: true,
      handler() {
        Object.keys(this.currentService.steps).forEach((lang) => {
          const activeStep = this.currentService.steps[lang].find((el) => el.stepId === this.currentStep.stepId);
          activeStep.cost = this.stepsSettings.cost;
        });
      },
    },
    'stepsSettings.timing': {
      handler() {
        Object.keys(this.currentService.steps).forEach((lang) => {
          const activeStep = this.currentService.steps[lang].find((el) => el.stepId === this.currentStep.stepId);
          activeStep.timing = this.stepsSettings.timing;
        });
      },
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideInside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutsideInside);
  },
  beforeMount() {
    const dict = {
      en: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
        },
        custom: {
          titleStep: {
            required: validationErrorMessage.en.inputRequired,
          },
          cost: {
            required: validationErrorMessage.en.inputRequired,
          },
          timing: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
        },
        custom: {
          titleStep: {
            required: validationErrorMessage.ru.inputRequired,
          },
          cost: {
            required: validationErrorMessage.ru.inputRequired,
          },
          timing: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.attach({ name: 'titleStep', rules: { required: true } });
    this.$validator.attach({ name: 'cost', rules: { required: true } });
    this.$validator.attach({ name: 'timing', rules: { required: true } });
  },
  methods: {
    clickOutsideInside(e) {
      if (!this.$el.contains(e.target)) {
        this.openWindowElementsInside = -1;
      }
    },
    groupRadioModal(data) {
      this.isVisibleGroupRadioModal = data;
    },
    updateRadioGroup(field, group) {
      this.isVisibleGroupRadioModal = false;
      const { fieldId } = field;
      const { stepId } = this.currentStep;
      Object.keys(this.allSteps).forEach((key) => {
        const currentStep = this.allSteps[key].find((step) => step.stepId === stepId);
        const { fields } = currentStep;
        const currentField = fields.find((field) => field.fieldId === fieldId);
        currentField.groupRadio = group;
      });
    },
    startMove() {
      const serviceModal = document.getElementById('service-modal');
      serviceModal.classList.add('admin-modal-wrap_start-drag');
    },
    onEnd() {
      const serviceModal = document.getElementById('service-modal');
      serviceModal.classList.remove('admin-modal-wrap_start-drag');
      const { steps } = this.currentService;
      const languagesArr = Object.keys(steps).filter((key) => key !== this.lang);
      languagesArr.forEach((key) => {
        const { stepId } = this.currentStep;
        const step = steps[key].find((item) => item.stepId === stepId);
        const { fields } = this.currentStep;
        const filedsArr = [];
        fields.forEach((field) => {
          const { fieldId } = field;
          const { fields } = step;
          const currentField = fields.find((item) => item.fieldId === fieldId);
          filedsArr.push(currentField);
        });
        step.fields = filedsArr;
      });
    },
    selectCurrency() {
      this.isVisibleCurrencyModal = false;
    },
    selectTiming(timing) {
      this.timingText = timing;
      this.isVisibleTimingModal = false;
    },
    showTimingModal() {
      this.isVisibleTimingModal = true;
    },
    showCurrencyModal() {
      this.isVisibleCurrencyModal = true;
    },
    saveParentField() {
      const { fields } = this.stepsSettings;
      const currentFieldIndex = findIndex(fields, ((field) => field.fieldId === this.currentFieldSettings.fieldId));
      fields.splice(currentFieldIndex, 1, this.currentFieldSettings);
      this.openWindowSettings();
    },
    changeParentSelect(e) {
      const { fieldId } = this.currentFieldSettings;
      const { stepId } = this.currentStep;
      this.currentFieldSettings.parentId = e;
      Object.keys(this.allSteps).forEach((key) => {
        const currentStep = this.allSteps[key].find((step) => step.stepId === stepId);
        const { fields } = currentStep;
        const currentField = fields.find((field) => field.fieldId === fieldId);
        this.currentFieldSettings = currentField;
        currentField.parentId = e;
      });
    },
    showSettings(el) {
      const allStepsFieldsList = this.allSteps[this.lang].reduce((acc, step) => [...acc, ...step.fields], []);
      const elementParentList = filter(allStepsFieldsList, (
        (field) => ((field.sub === SUB_TYPE_RADIO || field.sub === SUB_TYPE_CHECKBOX) && el.fieldId !== field.fieldId)
      ));
      return elementParentList.length;
    },
    settingsElFromStep(el) {
      const { parentId } = el;
      const parentIdArray = typeof parentId === 'number' && parentId === -1 ? [] : parentId;
      this.currentFieldSettings = {
        ...cloneDeep(el),
        parentId: typeof parentIdArray === 'number' ? [parentIdArray] : parentId,
      };
      this.parentComponentsList = [];
      const allStepsFieldsList = this.allSteps[this.lang].reduce((acc, step) => [...acc, ...step.fields], []);
      const elementParentList = filter(allStepsFieldsList, (
        (field) => ((field.sub === SUB_TYPE_RADIO || field.sub === SUB_TYPE_CHECKBOX) && el.fieldId !== field.fieldId)
      ));
      elementParentList.forEach((item) => {
        this.parentComponentsList.push({
          key: item.fieldId,
          text: item.value || item.placeholder.value[this.lang],
        });
      });
      this.showSelectParentField = true;
    },
    removeElFromStep(el, index) {
      this.isVisibleGroupRadioModal = -1;
      const fieldId = el.sub === SUB_TYPE_RADIO || el.sub === SUB_TYPE_CHECKBOX ? el.fieldId : -1;
      const { stepId } = this.stepsSettings;
      if (fieldId !== -1) {
        Object.keys(this.allSteps).forEach((key) => {
          this.allSteps[key].forEach((step) => {
            if (step.parentId.includes(fieldId)) {
              const fieldIndex = step.parentId.indexOf(fieldId);
              step.parentId.splice(fieldIndex, 1);
            }
            const { fields } = step;
            const childrenElList = filter(fields, ((field) => field.parentId.includes(fieldId)));
            childrenElList.forEach((field) => {
              const fieldIndex = field.parentId.indexOf(fieldId);
              field.parentId.splice(fieldIndex, 1);
            });
          });
        });
      }
      const currentStepIndex = findIndex(this.allSteps.en, ((step) => step.stepId === stepId));
      Object.keys(this.allSteps).forEach((key) => {
        this.allSteps[key][currentStepIndex].fields.splice(index, 1);
      });
    },
    addToStepList() {
      const { fields } = this.stepsSettings;
      if (this.stepsSettings.cost) {
        this.stepsSettings.cost = +this.stepsSettings.cost;
      }
      if (this.stepsSettings.timing) {
        this.stepsSettings.timing = +this.stepsSettings.timing;
      }
      const fieldsArray = filter(fields,
        (field) => field.value === '' || field.title === '');
      this.fieldsError = fieldsArray.map((item) => item.fieldId);
      this.$validator.validateAll({
        titleStep: this.stepsSettings.titleStep,
      }).then((result) => {
        if (result && !this.fieldsError.length) {
          this.scrollTo(window, this.scrollToElem(), 300, 'clear');
          if (this.stepsSettings.newStep) {
            const { stepId } = this.stepsSettings;
            delete this.stepsSettings.newStep;
            Object.keys(this.allSteps).forEach((key) => {
              const currentStep = this.allSteps[key].find((step) => step.stepId === stepId && step.newStep);
              if (currentStep) {
                delete currentStep.newStep;
              }
            });
          }
          this.$emit('closeStepSettings');
        }
      });
    },
    closeStepSetting() {
      this.scrollTo(window, this.scrollToElem(160), 300, 'clear');
      this.$emit('closeStepSettings', 'cancel');
    },
    scrollToElem(val = 250) {
      const scrollPage = document.getElementById('service-scroll');
      this.$nextTick(() => {
        const steps = document.getElementById('steps');
        const scrollPosition = steps.getBoundingClientRect().top + scrollPage.scrollTop;
        this.scrollTo(scrollPage, scrollPosition - scrollPage.getBoundingClientRect().bottom + val, 300, 'clear');
      });
    },
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollTop;
      const perTick = (difference / duration) * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollTop + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollTop === to) return false;
        this.scrollTo(element, to, duration - 10);
        return false;
      }, 10);
    },
    closeChooseWindow() {
      if (!this.selectOpenStatus) {
        this.chooseWindow = -1;
        this.toggleIconAction = -1;
        this.showSelectParentField = false;
        this.parentComponentsList = [];
        this.currentFieldSettings = {};
      }
    },
    openWindowSettings(id) {
      this.isVisibleGroupRadioModal = -1;
      this.openWindowElements = -1;
      this.openWindowElementsInside = -1;
      this.toggleIconAction = this.toggleIconAction === id ? -1 : id;
      this.chooseWindow = this.chooseWindow === id ? -1 : id;
      this.showSelectParentField = false;
      this.parentComponentsList = [];
      this.currentFieldSettings = {};
    },
    clickOutside() {
      if (!this.selectOpenStatus) {
        this.openWindowElements = -1;
        this.showSelectParentField = false;
        this.parentComponentsList = [];
        this.currentFieldSettings = {};
      }
    },
    openListElement(id) {
      this.isVisibleGroupRadioModal = -1;
      this.toggleIconAction = -1;
      this.openWindowElements = this.openWindowElements === id ? -1 : id;
      this.indexElement = -1;
      this.openWindowElementsInside = -1;
    },
    openListElementInside(id, indexElement) {
      this.isVisibleGroupRadioModal = -1;
      this.indexElement = indexElement;
      this.elementListWindow = this.elementListWindow === id ? -1 : id;
      this.openWindowElements = -1;
      this.toggleIconAction = -1;
      this.openWindowElementsInside = this.openWindowElementsInside === id ? -1 : id;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../sass/variables";

  .steps-settings {
    background: rgba($color-dodger-blue, .08);
    border-radius: $borderRadius;
    padding: 14px 30px 16px 14px;
    margin-bottom: 8px;

    &__row {
      display: flex;
      width: 100%;
    }

    .select-modal {
      width: 91px;
      padding: 3px 0;
      background: $color-white;
      box-shadow: 0 0 10px rgba($color-black, .1);
      border-radius: $borderRadius;
      margin-left: auto;
      margin-right: 15px;
      position: absolute;
      right: 0;
      z-index: 10;

      &__item {
        height: 36px;
        font: $font-size-md $font-global;
        line-height: 1.5;
        color: $color-black;
        transition: background-color .15s ease-in;
        cursor: pointer;
        padding: 9px 12px;

        &:hover {
          background: $color-alabaster;
        }
      }
    }

    &__icon {
      position: absolute;
      color: $color-dodger-blue;
      right: 10px;
      top: 35px;
      pointer-events: none;

      span {
        font-family: $font-global-medium;
      }
    }

    &__text {
      position: absolute;
      color: $color-dodger-blue;
      right: 10px;
      top: 35px;
      cursor: pointer;
      transition: opacity .15s ease-in;
      pointer-events: none;

      &:hover {
        opacity: .8;
      }
    }

    &__group {
      position: relative;

      & + .steps-settings__group {
        margin-left: 8px;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 21px 21px 21px 0;
      user-select: none;

      &-checkbox {
        margin-right: auto;
      }

      .steps-settings__item + .steps-settings__item {
        margin-left: 5px;
      }

    }
  }

  .open-window-elements {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    max-width: 780px;
    width: 100%;
    transition: opacity .2s ease-in;

    &_active {
      z-index: $z-index-biggest !important;

      .open-window-elements {
        &__icon {
          transform: rotate(45deg);
          z-index: $z-index-middle;
        }
      }
    }

    &_biggest {
      z-index: $z-index-max !important;
    }

    &_margin {
      margin: 10px 0 10px 0;
    }

    &_width {
      max-width: 780px;
      width: 100%;
    }

    &__icon {
      transition: all .2s ease-in;

      i {
       font-size: $font-size-xlg;
       color: $color-dodger-blue;
       position: relative;

       &:before {
         position: relative;
         z-index: 5;
       }

       &:after {
         position: absolute;
         content: '';
         background: $color-white;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         width: 15px;
         height: 15px;
         border-radius: 50%;
         z-index: 0;
       }
     }
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background: rgba($color-dodger-blue, .2);
      transition: all .2s ease-in;
    }

    &:hover {
      &:before {
        background: $color-dodger-blue;
        box-shadow: 0 0 5px rgba($color-dodger-blue, .4);
      }
    }
  }

  .insert-element {
    position: relative;
    margin-bottom: 30px;
    max-width: 806px;
    min-width: 806px;
    display: flex;
    width: 100%;
    align-items: center;
    z-index: 10;

    &-radio {
      position: absolute;
      right: 36px;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      display: flex;

      i {
        color: $color-dodger-blue;
        cursor: pointer;
        transition: color .15s ease-in;

        &:hover {
          color: $color-cornflower-blue;
        }
      }

      &__text {
        color: $color-silver-chalice;
        display: flex;

        span {
          margin-left: 2px;
        }
      }
    }

    &__icon {
      position: absolute;
      cursor: pointer;
      color: $color-dodger-blue;
      left: -20px;
      top: 10px;
      font-size: $font-size-icon-sm;
    }

    &_active {
      z-index: $z-index-biggest;
    }

    .open-window-elements {
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .action-elements {
    position: relative;
    left: 3px;
    font-size: $font-size-md;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $color-dodger-blue;
    cursor: pointer;
    transition: all .15s ease-in;

    &-choose {
      user-select: none;
      position: absolute;
      top: 12px;
      right: 10px;
      left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px rgba($color-black, .1);
      border-radius: $borderRadius;
      background-color: $color-white;
      color: $color-black;
      cursor: default;
      min-width: 143px;
      max-width: 143px;
      padding: 3px 0;
      z-index: -1;

      &_lg {
        min-width: 346px;
        max-width: 346px;
      }

      &-controls {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__button {
          line-height: 1.5;
          min-height: 36px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: background-color .15s ease-in;
          padding: 0 12px;
          font: $font-size-md $font-global;

          &:hover {
            background: $color-alabaster;
          }
        }
      }

      &-select {
        width: 100%;
        padding: 12px 16px;
      }
    }

    &:before {
      position: relative;
    }

    &:hover {
      background: $color-dodger-blue;
      color: $color-white;
      border-color: transparent;
    }
  }

  .ub-icon-cross-x {
    background: $color-dodger-blue;
    color: $color-white;

    &:before {
      font-size: $font-size-h6;
      left: -.5px;
    }
  }

  @keyframes nodeInserted {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 0.8;
    }
  }

  .item-dropzone-area {
    height: 6rem;
    background: #888;
    opacity: 0.8;
    animation-duration: 0.5s;
    animation-name: nodeInserted;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }

</style>
