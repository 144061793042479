import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  getCreatedTypeServices() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.get('service-type');
  },
  deleteTypeService(id) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.delete(`service-type/${id}`);
  },
  addTypeService(typeService) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.TYPE_SERVICES_API,
    });
    return instWithCred.post('service-type', typeService);
  },
  updateService(typeService) {
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.patch(`service-type/${typeService._id}`, { ...typeService, _id: undefined });
  },
};
