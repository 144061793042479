const managers = [
  {
    id: 1,
    radioText: ('global.yes'),
    value: 'OTHER_PERSON',
  },
  {
    id: 2,
    radioText: ('global.no'),
    value: 'ME',
  },
];

export default managers;
