import i18n from '@/i18n';

import {
  MANAGER,
} from '@/constants/listStaffRoles';

const staffInnovationsRoles = [
  {
    key: MANAGER,
    text: i18n.t('staffInnovationsRoles.manager'),
  },
];
export default staffInnovationsRoles;
