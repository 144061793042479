<template>
  <section class="admin-settings-page">
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
  </section>
</template>

<script>
import adminPageHeader from '@/components/adminPageHeader';

export default {
  name: 'Settings',
  components: {
    adminPageHeader,
  },
  computed: {
    headerPageOptions() {
      return {
        title: this.$t('settingsPage.title'),
      };
    },
  },
};
</script>

<style scoped>

</style>
