<template>
  <div class="admin-modal">
    <div
      id="service-modal"
      class="admin-modal-wrap"
    >
      <div class="admin-modal-wrap-header">
        <div class="admin-modal-wrap-header__title">
          {{ $t('servicesPage.buttonText') }}
        </div>
        <div class="admin-modal-wrap-header-lang">
          <dropDown
            :dropdownOptions="dropdownOptions"
            @changeDropdown="changeLanguage"
          />
        </div>
        <button
          class="admin-modal-wrap-header__close"
          @click="closeModal"
        >
          <i class="ub-icon-close-circle admin-modal-wrap-header__icon"></i>
        </button>
      </div>
      <VuePerfectScrollbar
        id="service-scroll"
        class="admin-modal-wrap-content"
      >
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('servicesPage.addServiceModal.nameService') }}
          </div>
          <textInput
            :typeInput="'text'"
            :autofocus="true"
            :value.sync="serviceTitle"
            :class="{'error': errors.has('nameService')}"
            :errorStatus="errors.has('nameService')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('nameService')"
              class="validation"
            >
              {{ errors.first('nameService') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('servicesPage.addServiceModal.country') }}
          </div>
          <selectWithKeyTemplate
            :settings="countrySelectSettings"
            :class="{'error': errors.has('setActiveCountry')}"
            @changeSelect="changeSelectCountry($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('setActiveCountry')"
              class="validation"
            >
              {{ errors.first('setActiveCountry') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('servicesPage.addServiceModal.typeService') }}
          </div>
          <selectWithKeyTemplate
            :settings="typeServiceSelectSettings"
            :class="{'error': errors.has('setActiveTypeService')}"
            @changeSelect="changeTypeService($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('setActiveTypeService')"
              class="validation"
            >
              {{ errors.first('setActiveTypeService') }}
            </span>
          </transition>
        </div>
        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('servicesPage.addServiceModal.countryForm') }}
          </div>
          <textInput
            :typeInput="'text'"
            :value.sync="formService"
            :class="{'error': errors.has('formServiceText')}"
            :errorStatus="errors.has('formServiceText')"
          >
          </textInput>
          <transition name="fade-validation">
            <span
              v-if="errors.has('formServiceText')"
              class="validation"
            >
              {{ errors.first('formServiceText') }}
            </span>
          </transition>
        </div>

        <div class="admin-form-field">
          <div class="admin-form-field__label">
            {{ $t('servicesPage.addServiceModal.currency') }}
          </div>
          <selectWithKeyTemplate
            :settings="currencySelectSettings"
            :class="{'error': errors.has('setActiveCurrency')}"
            @changeSelect="changeSelectCurrency($event)"
          />
          <transition name="fade-validation">
            <span
              v-if="errors.has('setActiveCurrency')"
              class="validation"
            >
              {{ errors.first('setActiveCurrency') }}
            </span>
          </transition>
        </div>

        <div class="admin-form-field">
          <div class="admin-form-field__label admin-form-field__label_desc">
            {{ $t('servicesPage.addServiceModal.description') }}
          </div>
          <ToolBarTextEditor
            :quill="quill"
            class="jodit"
            :formatting="activeStyle"
            :selectDown="true"
          />
          <div
            id="editorFull"
            class="ub-new-message-body__message"
          >
          </div>
        </div>

        <div class="characteristics__other">
          <div class="characteristics__title">
            {{ $t('servicesPage.addServiceModal.specificationsTitlePrepayment') }}
          </div>
          <div
            class="specification-settings__row"
          >
            <div class="specification-settings__form specification-settings__form_fullwidth">
              <textInput
                v-validate="'numeric|min_value:0|max_value:100'"
                :labelText="$t('servicesPage.addServiceModal.prepayment')"
                :typeInput="'number'"
                :name="'prePayment'"
                :class="{'error': errors.has('prePayment')}"
                :errorStatus="errors.has('prePayment')"
                :value.sync="currentService.prePayment"
              >
              </textInput>
              <transition name="fade-validation">
                <span
                  v-if="errors.has('prePayment')"
                  class="validation"
                >
                  {{ $t('servicesPage.addServiceModal.validationPrePayment') }}
                </span>
              </transition>
              <transition name="fade-validation">
                <div
                  v-if="!errors.has('prepayment')"
                  class="specification-settings__icon specification-settings__icon_right"
                >
                  <span>%</span>
                </div>
              </transition>
            </div>
          </div>
          <div
            class="specification-settings__row"
          >
            <div class="specification-settings__form specification-settings__form_fullwidth">
              <textInput
                v-validate="'numeric|min_value:1|max_value:100'"
                :labelText="$t('servicesPage.addServiceModal.partnerReward')"
                :typeInput="'numeric'"
                :name="'rewardRate'"
                :class="{'error': errors.has('rewardRate')}"
                :errorStatus="errors.has('rewardRate')"
                :value.sync="currentService.rewardRate"
              >
              </textInput>
              <transition name="fade-validation">
                <div
                  v-if="!errors.has('rewardRate')"
                  class="specification-settings__icon specification-settings__icon_right"
                >
                  <span>%</span>
                </div>
              </transition>
              <transition name="fade-validation">
                <span
                  v-if="errors.has('rewardRate')"
                  class="validation"
                >
                  {{ $t('servicesPage.addServiceModal.validationRewardRate') }}
                </span>
              </transition>
            </div>
          </div>
        </div>
        <div class="admin-modal-wrap-content-row">
          <div class="admin-modal-wrap-specification">
            <div class="admin-modal-wrap-specification__title">
              {{ $t('servicesPage.addServiceModal.specificationsTitleSelection') }}
            </div>
            <div
              class="specification-settings"
            >
              <div
                class="specification-settings__wrapper"
              >
                <div
                  class="specification-settings__row"
                >
                  <div class="specification-settings__column">
                    <div class="specification-settings__form">
                      <textInput
                        v-model="currentService.ownersCompanyAmount[0]"
                        v-validate="'numeric|min_value:1'"
                        :labelText="$t('servicesPage.addServiceModal.ownerCompany')"
                        :typeInput="'text'"
                        :name="'numberOfOwnersFrom'"
                        :value.sync="currentService.ownersCompanyAmount[0]"
                        :class="{'error': errors.has('numberOfOwnersFrom')}"
                        :errorStatus="errors.has('numberOfOwnersFrom')"
                      >
                      </textInput>
                      <transition name="fade-validation">
                        <span
                          v-if="errors.has('numberOfOwnersFrom')"
                          class="validation"
                        >
                          {{ errors.first('numberOfOwnersFrom') }}
                        </span>
                      </transition>
                    </div>
                    <div class="specification-settings__form">
                      <textInput
                        v-model="currentService.ownersCompanyAmount[1]"
                        v-validate="`numeric|min_value:${currentService.ownersCompanyAmount[0]}`"
                        :typeInput="'text'"
                        :value.sync="currentService.ownersCompanyAmount[1]"
                        :labelText="$t('servicesPage.addServiceModal.to')"
                        :name="'max-amount-number'"
                        :class="{'error': errors.has('max-amount-number') ||
                          errors.has('numberOfOwnersTo')}"
                        :errorStatus="errors.has('max-amount-number') ||
                          errors.has('numberOfOwnersTo')"
                      >
                      </textInput>
                      <transition name="fade-validation">
                        <span
                          v-if="errors.has('max-amount-number')"
                          class="validation"
                        >
                          {{ errors.first('max-amount-number') }}
                        </span>
                      </transition>
                      <transition name="fade-validation">
                        <span
                          v-if="errors.has('numberOfOwnersTo')"
                          class="validation"
                          :class="{'validation_bottom': errors.has('max-amount-number')}"
                        >
                          {{ errors.first('numberOfOwnersTo') }}
                        </span>
                      </transition>
                    </div>
                  </div>
                </div>
                <div
                  class="specification-settings__row"
                >
                  <div
                    class="specification-settings__form
                       specification-settings__form_column
                       specification-settings__form_fullwidth"
                  >
                    <span class="specification-settings__label">
                      {{ $t('servicesPage.addServiceModal.ownershipAllowed') }}
                    </span>
                    <div class="specification-settings__column">
                      <radio
                        :value.sync="ownershipAllowed"
                        :radioText.sync="radioTextAllowed"
                        :checked="currentService.isOwnershipAllowed"
                        :customClass="'ub-radio_alt'"
                        @radioChange="changeAllowed(true)"
                      />
                      <radio
                        :value.sync="ownershipNotAllowed"
                        :radioText.sync="radioTextNotAllowed"
                        :checked="!currentService.isOwnershipAllowed"
                        :customClass="'ub-radio_alt'"
                        @radioChange="changeAllowed(false)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="specification-settings__row"
                >
                  <div
                    class="specification-settings__form
                       specification-settings__form_column
                       specification-settings__form_fullwidth"
                  >
                    <span class="specification-settings__label">
                      {{ $t('servicesPage.addServiceModal.nonResidentDirector') }}
                    </span>
                    <div class="specification-settings__column">
                      <radio
                        :value.sync="residentDirector"
                        :radioText.sync="radioTextResidentDirector"
                        :checked="currentService.isNonResidentDirector"
                        :customClass="'ub-radio_alt'"
                        @radioChange="changeResidentDirector(true)"
                      />
                      <radio
                        :value.sync="nonResidentDirector"
                        :radioText.sync="radioTextNotResidentDirector"
                        :checked="!currentService.isNonResidentDirector"
                        :customClass="'ub-radio_alt'"
                        @radioChange="changeResidentDirector(false)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="specification-settings__row"
                >
                  <div
                    class="specification-settings__form
                       specification-settings__form_column
                       specification-settings__form_fullwidth"
                  >
                    <span class="specification-settings__label">
                      {{ $t('servicesPage.addServiceModal.director') }}
                    </span>
                    <div class="specification-settings__column">
                      <radio
                        v-for="manager in managers"
                        :key="manager.id"
                        :value.sync="manager.value"
                        :radioText="$t(manager.radioText)"
                        :checked="manager.value === currentService.director"
                        :customClass="'ub-radio_alt'"
                        @radioChange="changeDirector(manager.value)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="currentService.director !== 'ME'"
                  class="specification-settings__row"
                >
                  <transition name="fade-el">
                    <div
                      class="specification-settings__form
                       specification-settings__form_column
                       specification-settings__form_fullwidth"
                    >
                      <span class="specification-settings__label">
                        {{ $t('servicesPage.addServiceModal.directorCompany') }}
                      </span>
                      <div class="specification-settings__column">
                        <div
                          v-for="el in directorCompanyObj"
                          :key="el.key"
                          class="specification-settings__wrap"
                        >
                          <checkBox
                            :value="currentService.directorCompany.includes(el.key)"
                            :checkboxText="$t(el.text)"
                            :customClass="'ub-checkbox_alt'"
                            :class="{'error': errors.has('directorCompanyRules')}"
                            @changeCheckBox="() => changeCheckBox(el.key)"
                          />
                        </div>
                        <transition name="fade-validation">
                          <span
                            v-if="errors.has('directorCompanyRules')"
                            class="validation"
                          >
                            {{ errors.first('directorCompanyRules') }}
                          </span>
                        </transition>
                      </div>
                    </div>
                  </transition>
                </div>
                <div
                  class="specification-settings__row"
                >
                  <div
                    class="specification-settings__form
                     specification-settings__form_column
                     specification-settings__form_fullwidth"
                  >
                    <span class="specification-settings__label">
                      {{ $t('servicesPage.addServiceModal.labelFieldOfActivity') }}
                    </span>
                    <multipleSelectTemplate
                      :settings="fieldOfActivitySelectSettings"
                      :class="{'error': errors.has('setActiveService')}"
                      @changeSelect="changeFieldActivity($event)"
                    />
                    <transition name="fade-validation">
                      <span
                        v-if="errors.has('setActiveService')"
                        class="validation"
                      >
                        {{ errors.first('setActiveService') }}
                      </span>
                    </transition>
                  </div>
                </div>
                <div class="uploadFile">
                  <div class="characteristics__title uploadFile__title">
                    {{ $t('steps.commercialOffer') }}
                  </div>
                  <div class="uploadFile__body">
                    <div class="uploadFile__wrapper">
                      <Upload
                        class="button-uploadCommercialOffer"
                        :class="errorUploadFile
                          ? 'error'
                          : undefined"
                        :popupDown="false"
                        :multiple="false"
                        :upload="true"
                        :isHover.sync="isHover"
                        :item="fileObj"
                        :iconText="true"
                        :isIconUploadCommercialOffer="true"
                        :buttonText="false"
                        :buttonTextContent="$t('global.uploadFile')"
                        :fileCommercialOfferTitle="fileCommercialOfferTitle"
                        :accept="'application/msword, ' +
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ' +
                          'application/vnd.ms-excel, application/pdf, image/jpeg,'"
                        @upload="uploadCommercialOffer($event)"
                      />
                      <div
                        v-if="loaderIsCommercialOffer"
                        class="button-action__bottom loaderCommercialOffer"
                      >
                        <div class="button-action-progress">
                          <span class="button-action-progress__line"></span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-click-outside="clickOutsideCommercialOffer"
                      class="uploadFile__btnAction"
                    >
                      <div
                        class="uploadFile__action"
                        :class="isOpenWIndowForCommercialOffer
                          ? 'ub-icon-cross-x uploadFile__action_open'
                          : 'ub-icon-more-vertical'"
                        @click="isOpenWIndowForCommercialOffer = !isOpenWIndowForCommercialOffer"
                      >
                      </div>
                      <div
                        v-if="isOpenWIndowForCommercialOffer"
                        class="uploadFile__actions-container action-elements-choose"
                      >
                        <div class="action-elements-choose-controls">
                          <button
                            class="ub-button ub-toolbar-choose-controls__button uploadFile__btn"
                            @click.stop="deleteCommercialOffer"
                          >
                            {{ $t('buttons.delete') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="errorUploadFile"
                  class="textErrorUpload"
                >
                  {{ textErrorWhenUpload }}
                </div>
              </div>
            </div>
            <div class="admin-modal-wrap-content-row">
              <div class="characteristics">
                <div class="characteristics__title">
                  {{ $t('servicesPage.addServiceModal.specificationsTitle') }}
                </div>
                <draggable
                  v-model="allSpecificationsCurrentService"
                  class="characteristics-list"
                  .
                  handle=".characteristics-list__item_drag"
                  @end="onEndSpecification"
                >
                  <div
                    v-for="(specification, index) in allSpecificationsCurrentService"
                    :key="`key-${index}`"
                    class="characteristics-list__item"
                    :class="{'characteristics-list__item_drag': openSettingsStep !== specification.id}"
                  >
                    <transition
                      name="fade-el"
                      mode="out-in"
                    >
                      <specification-settings
                        v-if="openSpecification === specification.id"
                        :specification.sync="specification"
                        :currencyService="currentService.currency"
                        :specificationServices="specificationServices"
                        @closeSpecificationSettings="closeSpecificationSettings"
                      />
                      <div
                        v-else
                        class="characteristics__wrapper characteristics__wrapper_row"
                      >
                        <div
                          class="characteristics-item__content"
                          @click="toggleEditCurrentSpecification(specification)"
                        >
                          <div class="characteristics-item__id">
                            {{ index + 1 }}
                          </div>
                          <div class="characteristics-item__title">
                            {{ specification.name }}
                          </div>
                          <div
                            v-if="specification.type === 'text'"
                            class="characteristics-item__sum"
                          >
                            {{ specification.value }}
                          </div>
                          <div
                            v-if="specification.type === 'range'"
                            class="characteristics-item__sum"
                          >
                            <span
                              v-if="specification.rangeType === 'minmax'"
                              class="characteristics-item__val"
                            >
                              {{ $t('global.min') }}
                            </span>
                            {{ specification.value[0] }}
                            <span
                              v-if="specification.value.length === 2 && specification.value[1] !== ''"
                              class="characteristics-item__defis"
                            >
                              -
                            </span>
                            <span
                              v-if="specification.rangeType === 'minmax' && specification.value[1] !== ''
                                && specification.value.length === 2"
                              class="characteristics-item__val"
                            >
                              {{ $t('global.max') }}
                            </span>
                            {{ specification.value[1] }}
                            <span
                              v-if="specification.rangeType === 'day'"
                              class="characteristics-item__currency"
                            >
                              {{ calcNum(specification.value) }}
                            </span>
                            <span
                              v-else
                              class="characteristics-item__currency"
                            >
                              {{ currentService.currency }}
                            </span>
                          </div>
                          <div
                            v-if="specification.type === 'short'"
                            class="characteristics-item__sum"
                          >
                            <template v-if="specification.value">
                              {{ $t('global.yes') }}
                            </template>
                            <template v-else>
                              {{ $t('global.no') }}
                            </template>
                          </div>
                          <div
                            v-if="specification.type === 'optional'"
                            class="characteristics-item__sum"
                          >
                            <template v-if="specification.value">
                              {{ $t('global.required') }}
                            </template>
                            <template v-else>
                              {{ $t('global.notRequired') }}
                            </template>
                          </div>
                        </div>
                        <div
                          class="characteristics-item__settings"
                        >
                          <i
                            v-if="activeItem !== specification.id"
                            class="ub-icon-settings-more characteristics-item__icon"
                            @click="showMenu(specification.id)"
                          ></i>
                          <i
                            v-else
                            v-click-outside="clickOutside"
                            class="ub-icon-close
                        characteristics-item__icon
                        characteristics-item__icon_close"
                            @click="clickOutside"
                          ></i>
                          <transition
                            v-if="activeItem === specification.id"
                            name="fade-el"
                          >
                            <div
                              class="characteristics-settings"
                              @click="deleteSpecification(specification.id)"
                            >
                              <div class="characteristics-settings__item">
                                <div
                                  class="characteristics-settings__text"
                                >
                                  {{ $t('global.delete') }}
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </transition>
                  </div>
                  <button
                    v-if="openSpecification === -1"
                    class="button-action button-action_specification"
                    @click="addSpecificationStep"
                  >
                    <i class="ub-icon-cross"></i>
                    <span>
                      {{ $t('servicesPage.addServiceModal.addSpecification') }}
                    </span>
                  </button>
                </draggable>
              </div>
            </div>
            <div
              id="steps"
              class="steps"
            >
              <div class="steps__title">
                {{ $t('servicesPage.steps') }}
              </div>
              <div
                ref="refAllSteps"
                class="steps__scroll"
              >
                <draggable
                  v-model="allStepsCurrentService"
                  class="steps__list"
                  handle=".steps-item_drag"
                  @end="onEnd"
                >
                  <transition-group
                    name="fade-block"
                    mode="out-in"
                  >
                    <div
                      v-for="(elem, indexEl) in allStepsCurrentService"
                      :key="elem.stepId"
                      class="steps-item"
                      :class="[
                        {'steps-item_active': activeStep === elem.stepId},
                        {'steps-item_drag': openSettingsStep !== elem.stepId}
                      ]"
                      @click="toggleEditCurrentStep(elem)"
                    >
                      <transition
                        name="fade-el"
                        mode="out-in"
                      >
                        <stepsSettings
                          v-if="openSettingsStep === elem.stepId"
                          :currentStep.sync="elem"
                          :lang="language"
                          :currentService="currentService"
                          :allSteps.sync="currentService.steps"
                          @closeStepSettings="closeStepSettings"
                        />
                        <div
                          v-else
                          class="steps-item-wrap"
                        >
                          <div class="steps-item__content">
                            <div
                              class="steps-item__left"
                              :class="{'steps-item__left_width': !elem.cost && !elem.timing}"
                            >
                              <div class="steps-item__id">
                                {{ indexEl + 1 }}
                              </div>
                              <div
                                class="steps-item__title"
                                :class="{'steps-item__title_width': !elem.cost && !elem.timing}"
                              >
                                {{ elem.titleStep }}
                              </div>
                            </div>
                            <div class="steps-item__right">
                              <div
                                v-if="elem.cost"
                                class="steps-item__sum"
                              >
                                <span>{{ elem.cost }}
                                  {{ currentService.currency }}
                                </span>
                              </div>
                              <div
                                v-if="elem.timing"
                                class="steps-item__days"
                              >
                                <span>{{ elem.timing }} {{ $t('timingModal') }}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="action-elements"
                            :class="toggleIconAction === elem.stepId ? 'ub-icon-cross-x' : 'ub-icon-more-vertical'"
                            @click.stop="openWindowSettings(elem.stepId)"
                          >
                            <transition
                              name="fade-el"
                              mode="out-in"
                            >
                              <div
                                v-if="chooseWindow === elem.stepId"
                                v-click-outside="closeChooseWindow"
                                class="action-elements-choose"
                                :class="{'action-elements-choose_lg': showSelectParentField}"
                                @click.stop
                              >
                                <div
                                  v-if="showSelectParentField"
                                  class="action-elements-choose-select"
                                >
                                  <div class="admin-form-field">
                                    <div class="admin-form-field__label">
                                      {{ $t('servicesPage.addServiceModal.selectControl') }}
                                    </div>
                                    <multipleSelectTemplate
                                      :settings="parentSelectSettings"
                                      @changeSelect="changeParentSelect"
                                    />
                                  </div>
                                  <div class="admin-form-field admin-form-field_end">
                                    <button
                                      class="ub-btn ub-btn_primary"
                                      @click="saveParentField(elem)"
                                    >
                                      {{ $t('buttons.save') }}
                                    </button>
                                  </div>
                                </div>

                                <div
                                  v-else
                                  class="action-elements-choose-controls"
                                >
                                  <button
                                    v-if="showSettings(elem)"
                                    class="ub-button action-elements-choose-controls__button"
                                    @click.stop="settingsElFromStep(elem)"
                                  >
                                    {{ $t('buttons.settings') }}
                                  </button>
                                  <button
                                    class="ub-button action-elements-choose-controls__button"
                                    @click.stop="removeElFromStep(indexEl, elem.stepId)"
                                  >
                                    {{ $t('buttons.delete') }}
                                  </button>
                                </div>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <transition
                name="fade-btn"
                mode="out-in"
              >
                <button
                  v-show="openSettingsStep === -1"
                  class="button-action button-action_offset"
                  :class="[
                    {'button-action_disabled': openSettingsStep !== -1}
                  ]"
                  :disabled="openSettingsStep !== -1"
                  @click="addStep"
                >
                  <i class="ub-icon-cross"></i>
                  <span>{{ $t('servicesPage.addStep') }}</span>
                </button>
              </transition>
            </div>
          </div>
        </div>
      </VuePerfectScrollbar>
      <div class="admin-modal-wrap-footer">
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_cancel admin-modal-wrap-footer__cancel"
            @click="closeModal"
          >
            {{ $t('global.cancel') }}
          </button>
        </div>
        <div class="admin-modal-wrap-footer__item">
          <button
            class="ub-btn ub-btn_primary"
            :class="{'ub-btn_disabled': openSettingsStep !== -1 || loaderIsCommercialOffer}"
            :disabled="openSettingsStep !== -1 || loaderIsCommercialOffer"
            @click="addNewService"
          >
            <template v-if="editServiceStatus">
              {{ $t('buttons.save') }}
            </template>
            <template v-else>
              {{ $t('buttons.add') }}
            </template>
          </button>
        </div>
      </div>
    </div>
    <div
      class="admin-modal__overlay"
    ></div>
    <loader v-if="loader" />
  </div>
</template>

<script>
import stepsSettings from '@/components/stepsSettings';
import fieldOfActivity from '@/constants/fieldsOfActivity';
import ClickOutside from 'vue-click-outside';
import getUniqueId from '@/mixins/uniqueId';
import radio from '@/components/fields/radio';
import {
  findIndex, find, cloneDeep, sortBy, filter, get,
} from 'lodash';
import draggable from 'vuedraggable';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import multipleSelectTemplate from '@/components/fields/multipleSelectTemplate';
import countriesApi from '@/api/countries/countriesApi';
import textInput from '@/components/fields/textInput';
import servicesApi from '@/api/services/servicesApi';
import validationErrorMessage from '@/locales/validationErrorMessage';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToolBarTextEditor from '@/components/elements/description/toolbarTextEditor/toolbarTextEditor';
import joditConfig from '@/constants/joditConfig';
import dropDown from '@/components/fields/dropDown';
import getFormatting from '@/mixins/getFormatting';
import { Jodit } from 'jodit';
import loader from '@/components/loader';
import checkBox from '@/components/fields/checkBox';
import directorCompanyObj from '@/constants/selectionServicePage/selectionServiceDirectorsCompany';
import managers from '@/constants/selectionServicePage/selectionServiceManagers';
import typeServicesApi from '@/api/type-services/typeServicesApi';
import currency from '@/constants/currency';
import {
  EUR,
} from '@/constants/listFieldsCurrency';
import { SUB_TYPE_CHECKBOX, SUB_TYPE_RADIO } from '@/preset/elements/listSubTypes';
import specificationSettings from '@/components/specificationSettings';
import Upload from '@/components/Upload';
import filesApi from '@/api/files/filesApi';

export default {
  name: 'AddNewServiceModal',
  components: {
    stepsSettings,
    radio,
    draggable,
    VuePerfectScrollbar,
    selectWithKeyTemplate,
    multipleSelectTemplate,
    textInput,
    ToolBarTextEditor,
    dropDown,
    loader,
    checkBox,
    specificationSettings,
    Upload,
  },
  directives: {
    ClickOutside,
  },
  mixins: [
    getFormatting,
  ],
  props: {
    servicesList: {
      type: Array,
      default: () => [],
    },
    editService: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      directorCompanyObj,
      managers,
      currency,
      currentStepSettings: {},
      showSelectParentField: false,
      parentComponentsList: [],
      radioTextAllowed: this.$t('servicesPage.addServiceModal.allowed'),
      radioTextNotAllowed: this.$t('servicesPage.addServiceModal.notAllowed'),
      radioTextResidentDirector: this.$t('servicesPage.addServiceModal.can'),
      radioTextNotResidentDirector: this.$t('servicesPage.addServiceModal.cannot'),
      editServiceStatus: false,
      allSteps: [],
      openSettingsStep: -1,
      openNewStep: false,
      specificationSettings: [],
      loader: false,
      loaderIsCommercialOffer: false,
      setTimeoutId: '',
      setTimeoutIdBug: '',
      activeStep: -1,
      item: {},
      currentSpecificationValue: '',
      toggleIconAction: -1,
      chooseWindow: -1,
      nameService: '',
      formServiceText: '',
      descriptionDef: '',
      quill: '',
      ownershipAllowed: 1,
      ownershipNotAllowed: 2,
      nonResidentDirector: 1,
      residentDirector: 2,
      activeStyle: {},
      validator: {},
      languageDef: 'ru',
      activeItem: -1,
      currentServiceDef: {
        name: {
          ru: '',
          en: '',
        },
        description: {
          ru: '',
          en: '',
        },
        serviceTypes: '',
        managementForm: {
          en: '',
          ru: '',
        },
        country: '',
        activityAreas: [],
        steps: {
          ru: [],
          en: [],
        },
        specifications: {
          ru: [],
          en: [],
        },
        currency: EUR,
        rewardRate: 70,
        ownersCompanyAmount: [1, 1],
        isOwnershipAllowed: false,
        isNonResidentDirector: false,
        directorCompany: ['ENTITY', 'NATURAL_PERSON'],
        director: 'OTHER_PERSON',
        prePayment: 30,
      },
      openSpecification: -1,
      fileObj: {},
      isHover: false,
      isOpenWIndowForCommercialOffer: false,
      fileCommercialOfferTitle: '',
      isDeleteFile: false,
      serviceOfferFile: undefined,
      errorUploadFile: null,
    };
  },
  computed: {
    allSpecificationsCurrentService: {
      get() {
        if (this.currentService.specifications === undefined) {
          this.$set(this.currentService, 'specifications', {
            en: [],
            ru: [],
          });
        }
        return this.currentService.specifications[this.language];
      },
      set(data) {
        this.currentService.specifications[this.language] = data;
      },
    },
    allStepsCurrentService: {
      get() {
        return this.currentService.steps[this.language];
      },
      set(data) {
        this.currentService.steps[this.language] = data;
      },
    },
    specificationServices() {
      return get(this.currentService, 'specifications', {});
    },
    parentSelectSettings() {
      return {
        optionList: this.parentComponentsList,
        placeholderText: this.$t('servicesPage.addServiceModal.selectControl'),
        selectedKey: this.currentStepSettings.parentId,
        disable: false,
        toTop: false,
        tabindex: 1,
      };
    },
    language: {
      get() {
        return this.languageDef;
      },
      set(data) {
        this.languageDef = data;
      },
    },
    serviceTitle: {
      get() {
        return this.currentService.name[this.language];
      },
      set(data) {
        this.currentService.name[this.language] = data;
      },
    },
    formService: {
      get() {
        return this.currentService.managementForm[this.language];
      },
      set(data) {
        this.currentService.managementForm[this.language] = data;
      },
    },
    serviceDescription: {
      get() {
        if (this.currentService.description === undefined) {
          this.$set(this.currentService, 'description', { ru: '', en: '' });
        }
        return this.currentService.description[this.language];
      },
      set(data) {
        this.currentService.description[this.language] = data;
      },
    },
    currentService: {
      get() {
        return this.currentServiceDef;
      },
      set(data) {
        this.currentServiceDef = data;
      },
    },
    dropdownOptions() {
      return {
        list: [
          {
            code: 'ru',
            text: 'Russian',
          },
          {
            code: 'en',
            text: 'English',
          },
        ],
        value: this.language,
      };
    },
    description: {
      get() {
        return this.descriptionDef;
      },
      set(data) {
        this.descriptionDef = data;
      },
    },
    allTypeServicesList: {
      get() {
        return this.$store.getters.allTypeServicesList;
      },
      set(data) {
        this.$store.dispatch('setAllTypeServicesList', data);
      },
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    createdCountriesList() {
      return this.allCountriesList.filter((country) => country.isVisible);
    },
    selectCountriesList() {
      const newArr = [];
      const arrSortByName = sortBy(this.createdCountriesList, `name.${this.language}`);
      arrSortByName.forEach((item) => {
        newArr.push({
          text: item.name[this.language],
          key: item._id,
          image: `country/${item.code}.svg`,
        });
      });
      return newArr;
    },
    selectCurrencyList() {
      const newArr = [];
      const arrSortByName = sortBy(this.currency, 'text');
      arrSortByName.forEach((item) => {
        newArr.push({
          text: item.text,
          key: item.key,
        });
      });
      return newArr;
    },
    selectTypeServicesList() {
      const newArr = [];
      this.allTypeServicesList.forEach((item) => {
        newArr.push({
          text: item.name[this.language],
          key: item._id,
        });
      });
      return newArr;
    },
    typeServiceSelectSettings() {
      return {
        optionList: this.selectTypeServicesList,
        placeholderText: this.$t('servicesPage.addServiceModal.typeServicePlaceholder'),
        selectedKey: this.currentService.serviceTypes,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
      };
    },
    selectFormList() {
      const newArr = [];
      if (this.currentService.country) {
        const forms = this.createdCountriesList.find((item) => item._id === this.currentService.country);
        if (forms) {
          forms.managementForm[this.language].forEach((item) => {
            newArr.push({
              text: item,
              key: item,
            });
          });
        }
      }
      return newArr;
    },
    countrySelectSettings() {
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: this.currentService.country,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    currencySelectSettings() {
      return {
        optionList: this.selectCurrencyList,
        placeholderText: this.$t('countryPage.placeholderSelectCurrency'),
        selectedKey: this.currentService.currency,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        textCode: true,
      };
    },
    fieldOfActivityList() {
      const arr = [];
      fieldOfActivity.forEach((item) => {
        arr.push({
          ...item,
          text: this.$t(item.text),
        });
      });
      return arr;
    },
    fieldOfActivitySelectSettings() {
      return {
        optionList: this.fieldOfActivityList,
        placeholderText: this.$t('servicesPage.addServiceModal.placeholderFieldOfActivity'),
        selectedKey: this.currentService.activityAreas,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select_alt',
      };
    },
    managementForm() {
      return {
        optionList: this.selectFormList,
        placeholderText: this.$t('servicesPage.addServiceModal.placeholderForm'),
        selectedKey: this.currentService.managementForm[this.language],
        disable: this.currentService.country === '',
        toTop: false,
        tabindex: 1,
        selectClass: '',
      };
    },
    textErrorWhenUpload() {
      switch (this.errorUploadFile) {
      case 'error size': return this.$t('global.fileSizeExceeds15Mb');
      case 'error format': return this.$t('global.incorrectFileFormat');
      default: return this.$t('global.incorrectFormatAndSizeExceeds15MbOfFile');
      }
    },
  },
  beforeMount() {
    typeServicesApi.getCreatedTypeServices().then((resp) => {
      this.allTypeServicesList = resp.data.resource;
    }).catch((err) => {
      console.error(err);
    });
    const dict = {
      en: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
          _default: (min, max) => `${this.$t('servicesPage.addServiceModal.fieldValue')} ${max[0]}`,
        },
        custom: {
          setActiveCountry: {
            required: validationErrorMessage.en.inputRequired,
          },
          setActiveCurrency: {
            required: validationErrorMessage.en.inputRequired,
          },
          setActiveService: {
            required: validationErrorMessage.en.inputRequired,
          },
          numberOfOwnersFrom: {
            required: validationErrorMessage.en.inputRequired,
          },
          numberOfOwnersTo: {
            required: validationErrorMessage.en.inputRequired,
          },
          nameService: {
            required: validationErrorMessage.en.inputRequired,
          },
          formServiceText: {
            required: validationErrorMessage.en.inputRequired,
          },
          setActiveTypeService: {
            required: validationErrorMessage.en.inputRequired,
          },
          directorCompanyRules: {
            required: validationErrorMessage.en.inputRequired,
          },
        },
      },
      ru: {
        messages: {
          numeric: () => `${this.$t('loginPage.loginForm.insertNumber')}`,
          _default: (min, max) => `${this.$t('servicesPage.addServiceModal.fieldValue')} ${max[0]}`,
        },
        custom: {
          setActiveCountry: {
            required: validationErrorMessage.ru.inputRequired,
          },
          setActiveCurrency: {
            required: validationErrorMessage.ru.inputRequired,
          },
          setActiveService: {
            required: validationErrorMessage.ru.inputRequired,
          },
          numberOfOwnersFrom: {
            required: validationErrorMessage.ru.inputRequired,
          },
          numberOfOwnersTo: {
            required: validationErrorMessage.ru.inputRequired,
          },
          nameService: {
            required: validationErrorMessage.ru.inputRequired,
          },
          formServiceText: {
            required: validationErrorMessage.ru.inputRequired,
          },
          setActiveTypeService: {
            required: validationErrorMessage.ru.inputRequired,
          },
          directorCompanyRules: {
            required: validationErrorMessage.ru.inputRequired,
          },
        },
      },
    };
    this.$validator.localize(dict);
    this.$validator.extend('directorCompany', (value) => value.length > 0);
    this.$validator.attach({ name: 'nameService', rules: { required: true } });
    this.$validator.attach({ name: 'setActiveTypeService', rules: { required: true } });
    this.$validator.attach({ name: 'numberOfOwnersFrom', rules: { required: true } });
    this.$validator.attach({ name: 'numberOfOwnersTo', rules: { required: true } });
    this.$validator.attach({ name: 'setActiveCountry', rules: { required: true } });
    this.$validator.attach({ name: 'setActiveCurrency', rules: { required: true } });
    this.$validator.attach({ name: 'setActiveService', rules: { required: true } });
    this.$validator.attach({ name: 'formServiceText', rules: { required: true } });
    this.$validator.attach({ name: 'directorCompanyRules', rules: { required: true, directorCompany: true } });
    if (this.allCountriesList.length === 0) {
      countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
        return arr;
      }).catch((err) => {
        console.error(err);
      });
    }

    if (Object.keys(this.editService).length) {
      const editService = cloneDeep(this.editService);
      this.currentService = {
        ...editService,
        country: editService.country._id,
        director: editService.director.code,
        serviceTypes: editService.serviceTypes[0],
        prePayment: +editService.prePayment,
        rewardRate: +editService.rewardRate,
        activityAreas: editService.activityAreas.map((el) => el.code),
        directorCompany: editService.directorCompany.map((el) => el.code),
      };
      this.editServiceStatus = true;
    } else {
      this.editServiceStatus = false;
    }
    this.language = this.$i18n.locale;
  },
  mounted() {
    this.serviceOfferFile = this.currentService.serviceOfferFile;
    this.fileCommercialOfferTitle = this.currentService?.serviceOfferFile?.originalName || '';

    this.quill = new Jodit('#editorFull', joditConfig);
    this.quill.value = this.serviceDescription;
    this.quill.events.on('click', (e) => {
      this.getStyles(e);
    });
    this.quill.events.on('blur', () => {
      this.joditOnBlur();
    });
  },
  methods: {
    checkTypeOfDownloadFile(type) {
      switch (type) {
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return null;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return null;
      case 'application/msword': return null;
      case 'application/vnd.ms-excel': return null;
      case 'application/pdf': return null;
      case 'image/jpeg': return null;
      default: return 'error format';
      }
    },
    checkingValidFile(file) {
      const { size, type } = file;

      if (size > 15728600 && this.checkTypeOfDownloadFile(type)) return 'error size and format';

      if (size > 15728600) return 'error size';

      return this.checkTypeOfDownloadFile(type);
    },
    deleteCommercialOffer() {
      if (this.loaderIsCommercialOffer) this.loaderIsCommercialOffer = false;
      this.isOpenWIndowForCommercialOffer = false;
      this.serviceOfferFile = undefined;
      this.fileCommercialOfferTitle = '';
      this.isDeleteFile = true;
    },
    uploadFiles(fileData) {
      this.loaderIsCommercialOffer = true;
      filesApi.createFile(fileData)
        .then((resp) => {
          this.loaderIsCommercialOffer = false;
          const { url, originalName, _id } = resp.data;
          this.serviceOfferFile = {
            originalName,
            url,
            _id,
          };
          this.fileCommercialOfferTitle = originalName;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setFileData(file) {
      const fileData = new FormData();
      fileData.set('fieldname', 'uploadFile');
      fileData.set('label', file.name || '');
      fileData.set('file', file);
      return fileData;
    },
    uploadCommercialOffer(item) {
      const [file] = item;

      if (this.checkingValidFile(file)) {
        this.errorUploadFile = this.checkingValidFile(file);
        return;
      }

      this.errorUploadFile = null;
      this.fileCommercialOfferTitle = file.name;
      const fileFull = this.setFileData(file);
      this.uploadFiles(fileFull);
    },
    calcNum(value) {
      const texts = [this.$t('global.dayOne'), this.$t('global.dayAlternative'), this.$t('global.days')];
      const number = value;
      const cases = [2, 0, 1, 1, 1, 2];
      return texts[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    showMenu(id) {
      this.activeItem = id;
    },
    clickOutsideCommercialOffer() {
      this.isOpenWIndowForCommercialOffer = false;
    },
    clickOutside() {
      this.activeItem = -1;
    },
    closeSpecificationSettings(cancel) {
      const currentSpecification = find(
        this.allSpecificationsCurrentService, ((specification) => specification.id === this.openSpecification),
      );
      if (currentSpecification.newSpecification && cancel) {
        const currentSpecificationIndex = findIndex(
          this.allSpecificationsCurrentService, ((specification) => specification.id === this.openSpecification),
        );
        Object.keys(this.specificationServices).forEach((key) => {
          this.currentService.specifications[key].splice(currentSpecificationIndex, 1);
        });
      }
      this.openSpecification = -1;
    },
    addSpecificationStep() {
      const newSpecification = {
        id: getUniqueId(),
        name: '',
        value: [],
        newSpecification: true,
      };
      Object.keys(this.specificationServices).forEach((key) => {
        this.currentService.specifications[key].push(cloneDeep(newSpecification));
      });
      this.activeItem = -1;
      this.openSpecification = newSpecification.id;
    },
    deleteSpecification(id) {
      const index = this.allSpecificationsCurrentService.findIndex((el) => el.id === id);
      if (index !== -1) {
        Object.keys(this.specificationServices).forEach((key) => {
          this.currentService.specifications[key].splice(index, 1);
        });
      }
    },
    toggleEditCurrentSpecification(el) {
      const currentSpecification = find(
        this.allSpecificationsCurrentService, ((specification) => specification.id === this.openSpecification),
      );
      if (!currentSpecification || !currentSpecification.newSpecification) {
        this.openSpecification = el.id;
      }
    },
    saveParentField() {
      this.currentStepSettings = {};
      this.openWindowSettings();
    },
    changeParentSelect(e) {
      const { stepId } = this.currentStepSettings;
      this.currentStepSettings.parentId = e;
      const { steps } = this.currentService;
      Object.keys(steps).forEach((key) => {
        const currentStep = steps[key].find((step) => step.stepId === stepId);
        currentStep.parentId = e;
      });
    },
    onEnd() {
      const { steps } = this.currentService;
      const languagesArr = Object.keys(steps).filter((key) => key !== this.language);
      languagesArr.forEach((key) => {
        const stepsArr = [];
        this.allStepsCurrentService.forEach((step) => {
          const { stepId } = step;
          const currentStep = steps[key].find((item) => item.stepId === stepId);
          stepsArr.push(currentStep);
        });
        steps[key] = stepsArr;
      });
    },
    onEndSpecification() {
      const { specifications } = this.currentService;
      const languagesArr = Object.keys(specifications).filter((key) => key !== this.language);
      languagesArr.forEach((key) => {
        const specificationsArr = [];
        this.allSpecificationsCurrentService.forEach((specification) => {
          const { id } = specification;
          const currentStep = specifications[key].find((item) => item.id === id);
          specificationsArr.push(currentStep);
        });
        specifications[key] = specificationsArr;
      });
    },
    changeCheckBox(e) {
      const index = this.currentService.directorCompany.findIndex((el) => el === e);
      if (index === -1) {
        this.currentService.directorCompany.push(e);
      } else {
        this.currentService.directorCompany.splice(index, 1);
      }
    },
    changeAllowed(val) {
      this.currentService.isOwnershipAllowed = val;
    },
    changeResidentDirector(val) {
      this.currentService.isNonResidentDirector = val;
    },
    changeDirector(val) {
      this.currentService.director = val;
    },
    joditOnBlur() {
      this.serviceDescription = this.quill.value;
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    changeLanguage(code) {
      this.serviceDescription = this.quill.value;
      this.language = code;
      this.quill.value = this.currentService.description[code];
    },
    getStyles(e) {
      if (e) {
        this.activeStyle = this.getFormatting(e.path);
      }
    },
    saveServiceQuery() {
      this.loader = true;
      const {
        prePayment,
        steps,
      } = this.currentService;
      const currentSteps = cloneDeep(steps);
      Object.keys(currentSteps).forEach((key) => {
        currentSteps[key].forEach((step) => {
          if (!step.cost) {
            step.cost = 0;
          }
          if (!step.timing) {
            step.timing = 0;
          }
        });
      });
      const service = {
        ...this.currentService,
        steps: currentSteps,
        prePayment: +prePayment,
        serviceOfferFile: this.serviceOfferFile,
      };
      servicesApi.updateService(service)
        .then((resp) => {
          this.loader = false;
          const country = find(this.createdCountriesList, (item) => item._id === resp.data.country);
          const currentServiceIndex = findIndex(this.servicesList, (
            (service) => service._id === this.editService._id));
          this.servicesList.splice(currentServiceIndex, 1, {
            ...resp.data,
            steps,
            country: {
              ...country,
              image: this.getOptionImage(country.image),
            },
          });
          this.$emit('closeAddNewMenu');
        })
        .catch((err) => {
          this.loader = false;
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    addServiceQuery() {
      this.loader = true;
      const {
        prePayment,
        rewardRate,
        steps,
      } = this.currentService;
      const currentSteps = cloneDeep(steps);
      Object.keys(currentSteps).forEach((key) => {
        currentSteps[key].forEach((step) => {
          if (!step.cost) {
            step.cost = 0;
          }
          if (!step.timing) {
            step.timing = 0;
          }
        });
      });
      const service = {
        ...this.currentService,
        steps: currentSteps,
        prePayment: +prePayment,
        rewardRate: +rewardRate,
        serviceOfferFile: this.serviceOfferFile,
      };
      servicesApi.addService(service)
        .then((resp) => {
          this.loader = false;
          const country = find(this.createdCountriesList, (item) => item._id === resp.data.country);
          this.servicesList.unshift({
            ...resp.data,
            steps,
            country: {
              ...country,
              image: this.getOptionImage(country.image),
            },
          });
          this.$emit('closeAddNewMenu');
        })
        .catch((err) => {
          this.loader = false;
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    addNewService() {
      if (this.openSettingsStep !== -1) {
        return true;
      }
      this.serviceDescription = this.quill.value;
      this.$validator.validateAll({
        nameService: this.currentService.name[this.language],
        setActiveTypeService: this.currentService.serviceTypes,
        numberOfOwnersFrom: +this.currentService.ownersCompanyAmount[0],
        numberOfOwnersTo: +this.currentService.ownersCompanyAmount[1],
        setActiveCountry: this.currentService.country,
        setActiveCurrency: this.currentService.currency,
        setActiveService: this.currentService.activityAreas,
        formServiceText: this.currentService.managementForm[this.language],
        directorCompanyRules: this.currentService.directorCompany,
        rewardRate: +this.currentService.rewardRate,
        prePayment: +this.currentService.prePayment,
        'max-amount-number': +this.currentService.ownersCompanyAmount[1],
      }).then((result) => {
        if (result) {
          if (this.currentService.name.ru === '' || this.currentService.name.en === '') {
            this.currentService.name.ru = this.serviceTitle;
            this.currentService.name.en = this.serviceTitle;
          }
          if (this.currentService.managementForm.ru === '' || this.currentService.managementForm.en === '') {
            this.currentService.managementForm.ru = this.formService;
            this.currentService.managementForm.en = this.formService;
          }
          if (this.editServiceStatus) {
            this.saveServiceQuery();
          } else {
            this.addServiceQuery();
          }
        } else {
          const scrollPage = document.getElementById('service-scroll');
          const scrollPageTop = scrollPage.getBoundingClientRect().top;
          setTimeout(() => {
            const myElement = document.querySelectorAll('.validation');
            if (myElement[0] !== undefined) {
              const scrollPosition = myElement[0].getBoundingClientRect().top + scrollPage.scrollTop;
              this.scrollTo(scrollPage, scrollPosition - scrollPageTop - 60, 300, 'clear');
            }
          }, 150);
        }
      });
      return true;
    },
    changeSelectCountry(key) {
      this.currentService.country = key;
    },
    changeSelectCurrency(key) {
      this.currentService.currency = key;
      this.openSpecification = -1;
    },
    changeTypeService(key) {
      this.currentService.serviceTypes = key;
    },
    changeFieldActivity(key) {
      this.currentService.activityAreas = key;
    },
    closeStepSettings(cancel) {
      if (this.openNewStep && cancel) {
        const currentStepIndex = findIndex(this.allStepsCurrentService,
          ((step) => step.stepId === this.openSettingsStep));
        const { steps } = this.currentService;
        Object.keys(steps).forEach((key) => {
          steps[key].splice(currentStepIndex, 1);
        });
      }
      this.openNewStep = false;
      this.openSettingsStep = -1;
    },
    toggleEditCurrentStep(el) {
      if (!this.openNewStep) {
        this.openSettingsStep = el.stepId;
      }
    },
    showSettings(el) {
      const allSteps = this.currentService.steps;
      const allStepsFieldsList = allSteps[this.language].reduce((acc, step) => [...acc, ...step.fields], []);
      const elementParentList = filter(allStepsFieldsList, (
        (field) => ((field.sub === SUB_TYPE_RADIO || field.sub === SUB_TYPE_CHECKBOX) && el.fieldId !== field.fieldId)
      ));
      return elementParentList.length;
    },
    settingsElFromStep(el) {
      const { parentId } = el;
      const parentIdArray = typeof parentId === 'number' && parentId === -1 ? [] : parentId;
      this.currentStepSettings = {
        ...cloneDeep(el),
        parentId: typeof parentIdArray === 'number' ? [parentIdArray] : parentId,
      };
      this.parentComponentsList = [];
      const allSteps = this.currentService.steps;
      const allStepsFieldsList = allSteps[this.language].reduce((acc, step) => [...acc, ...step.fields], []);
      const elementParentList = filter(allStepsFieldsList, (
        (field) => ((field.sub === SUB_TYPE_RADIO || field.sub === SUB_TYPE_CHECKBOX) && el.fieldId !== field.fieldId)
      ));
      elementParentList.forEach((item) => {
        this.parentComponentsList.push({
          key: item.fieldId,
          text: item.value || item.placeholder.value[this.language],
        });
      });
      this.showSelectParentField = true;
    },
    removeElFromStep(index) {
      const { steps } = this.currentService;
      Object.keys(steps).forEach((key) => {
        steps[key].splice(index, 1);
      });
    },
    closeChooseWindow() {
      this.chooseWindow = -1;
      this.toggleIconAction = -1;
      this.showSelectParentField = false;
      this.parentComponentsList = [];
      this.currentStepSettings = {};
    },
    openWindowForCommercialOffer() {
      this.isOpenWIndowForCommercialOffer = true;
    },
    openWindowSettings(id) {
      this.activeStep = id;
      if (this.toggleIconAction === id) {
        this.toggleIconAction = -1;
      } else {
        this.toggleIconAction = id;
      }
      if (this.chooseWindow === id) {
        this.chooseWindow = -1;
      } else {
        this.chooseWindow = id;
      }
      this.showSelectParentField = false;
      this.currentStepSettings = {};
    },
    scrollToElem() {
      const scrollPage = document.getElementById('service-scroll');
      this.$nextTick(() => {
        const scrollPosition = this.$refs.refAllSteps.getBoundingClientRect().top + scrollPage.scrollTop;
        this.scrollTo(scrollPage, scrollPosition - scrollPage.getBoundingClientRect().top, 250, 'clear');
      });
    },
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollTop;
      const perTick = (difference / duration) * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollTop + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollTop === to) return false;
        this.scrollTo(element, to, duration - 10);
        return false;
      }, 10);
    },
    addStep() {
      const newStep = {
        stepId: getUniqueId(),
        parentId: [],
        newStep: true,
        labelStep: this.$t('servicesPage.stepName'),
        titleStep: '',
        labelCost: this.$t('servicesPage.labelCost'),
        cost: '',
        labelTiming: this.$t('servicesPage.timing'),
        timing: '',
        fields: [],
        isRequired: true,
      };
      this.openNewStep = true;
      this.openSettingsStep = newStep.stepId;
      const { steps } = this.currentService;
      Object.keys(steps).forEach((key) => {
        steps[key].push(cloneDeep(newStep));
      });
      this.$nextTick(() => {
        this.scrollTo(window, this.scrollToElem(), 300, 'clear');
      });
    },
    closeModal() {
      this.currentService = {};
      this.$emit('closeAddNewMenu');
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/variables";
@import "../../sass/transitions";

.admin-modal-wrap {
  max-width: 880px;
  width: 100%;
  height: auto;

  .textErrorUpload {
    color: $color-cardinal;
    font: $font-size-sm $font-global;
    line-height: 1.55;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .uploadFile {

    &__wrapper {
      max-width: 800px;
      width: 100%;
    }

    &__body {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    &__btnAction {
      position: relative;
    }

    &__action {
      position: relative;
      font-size: $font-size-md;
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $color-dodger-blue;
      cursor: pointer;
      transition: color .15s ease-in, background-color .15s ease-in;
      z-index: 5;

      &:hover {
        color: $color-white;
        background-color: $color-dodger-blue;
      }
    }

    &__actions-container {
      z-index: 2;
    }

    &__btn {
      min-height: 36px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color .15s ease-in;
      padding: 0 12px;
      font: $font-size-md $font-global;

      &:hover {
        background-color: $color-alabaster;
      }
    }
  }

  .uploadFile__action_open {
    color: $color-white;
    background-color: $color-dodger-blue;
  }

  .loaderCommercialOffer {
    position: relative;
    top: -10px;
  }

  .button-uploadCommercialOffer {
    transition: all .15s ease-in;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px dashed $color-dodger-blue;
    color: $color-dodger-blue;
    border-radius: 4px;
    min-height: 40px;
    max-height: 40px;
    font-weight: 600;

    &:hover {
      background-color: rgba($color-dodger-blue, 0.15);
    }
  }

  .button-uploadCommercialOffer.error {
    border: 1px dashed $color-cardinal;
  }

  .specification-settings {

    &__row {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      user-select: none;

      .specification-settings {

        &__label {
          font-size: $font-size-md;
          margin-bottom: 3px;
        }

        &__icon {
          position: absolute;
          color: $color-dodger-blue;
          right: 12px;
          top: 30px;
          pointer-events: none;

          span {
            font-family: $font-global-medium;
          }

          &_right {
            right: 15px;
          }
        }

        &__column {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .specification-settings {
            &__form {
              min-width: 397px;
              max-width: 397px;
              min-height: 60px;
              max-height: 60px;
              position: relative;

              &_width {
                min-width: 400px;
                max-width: 400px;
              }
            }
          }
        }
      }

      .specification-settings__form {
        min-width: 456px;
        max-width: 456px;
        align-items: flex-end;
        display: flex;
        font: $font-size-base $font-global-medium;
        line-height: 1.55;
        color: $color-silver-chalice;
        position: relative;

        &_column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-width: 456px;
          max-width: 456px;
        }

        &_lesswidth {
          min-width: 417px !important;
          max-width: 417px !important;

          &:before {
            display: none;
          }
        }

        &_fullwidth {
          min-width: 100%;
          max-width: 100%;
        }

        & .text-field {
          width: 100%;
        }

        & + .specification-settings__form {
          min-width: 397px;
          max-width: 397px;
          min-height: 60px;
          max-height: 60px;

          &_width {
            min-width: 318px;
            max-width: 318px;
          }

          &:before {
            content: '';
            position: absolute;
            height: 1px;
            background: $color-black;
            top: 40px;
            width: 7px;
            left: -29px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;

      &__cancel {
        margin-right: 5px;
        background: transparent;
      }
    }
  }

  .characteristics {
    border-top: 1px solid rgba($color-dodger-blue, .08);
    padding-top: 24px;
    margin-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($color-dodger-blue, .08);

    &__other {
      border-top: 1px solid rgba($color-dodger-blue, .08);
      padding-top: 24px;
      margin-top: 24px;
    }

    &__title {
      font: $font-size-h2 $font-global-medium;
      color: $color-dodger-blue;
      margin-bottom: 31px;
    }
    &__title.uploadFile__title {
      margin: 24px 0 14px;
    }

    &__row {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      user-select: none;

      .characteristics {

        &__label {
          font-size: $font-size-md;
          margin-bottom: 3px;
        }

        &__icon {
          position: absolute;
          color: $color-dodger-blue;
          right: 12px;
          top: 30px;
          pointer-events: none;

          span {
            font-family: $font-global-medium;
          }

          &_right {
            right: 15px;
          }
        }

        &__column {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .specification-settings {
            &__form {
              min-width: 397px;
              max-width: 397px;
              min-height: 60px;
              max-height: 60px;
              position: relative;

              &_width {
                min-width: 400px;
                max-width: 400px;
              }
            }
          }
        }
      }

      .characteristics__form {
        min-width: 456px;
        max-width: 456px;
        align-items: flex-end;
        display: flex;
        font: $font-size-base $font-global-medium;
        line-height: 1.55;
        color: $color-silver-chalice;
        position: relative;

        &_column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-width: 456px;
          max-width: 456px;
        }

        &_lesswidth {
          min-width: 417px !important;
          max-width: 417px !important;

          &:before {
            display: none;
          }
        }

        &_fullwidth {
          min-width: 100%;
          max-width: 100%;
        }

        & .text-field {
          width: 100%;
        }

        & + .characteristics__form {
          min-width: 397px;
          max-width: 397px;
          min-height: 60px;
          max-height: 60px;

          &_width {
            min-width: 318px;
            max-width: 318px;
          }

          &:before {
            content: '';
            position: absolute;
            height: 1px;
            background: $color-black;
            top: 40px;
            width: 7px;
            left: -29px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;

      &__cancel {
        margin-right: 5px;
        background: transparent;
      }
    }
  }

  &-header-lang {
    margin-right: 40px;
  }

  &__editor {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba($color-dodger-blue, .08);
  }

  &__title {
    font: $font-size-h2 $font-global-medium;
    color: $color-dodger-blue;
    margin-bottom: 6px;
  }

  &-content {

    &-row {

      & .text-field {
        margin-top: 0;
      }
    }
  }

  &-specification {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid rgba($color-dodger-blue, .08);
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($color-dodger-blue, .08);

    &-settings {
      width: 143px;
      min-width: 143px;
      height: 42px;
      box-shadow: 0 0 10px rgba($color-black, .1);
      border-radius: $borderRadius;
      position: absolute;
      right: 12px;
      top: 23px;
      z-index: -1;
      display: flex;
      justify-content: flex-start;
      background: $color-white;
      padding: 3px 0;
      align-items: center;
      cursor: pointer;

      &__item {
        padding: 9px 12px;
        width: 100%;
        transition: background-color .15s ease-in;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          background: $color-alabaster;
        }
      }

      &__text {
        font: $font-size-md $font-global;
      }
    }

    &__title {
      font-family: $font-global-medium;
      font-size: $font-size-h2;
      line-height: 1.5;
      color: $color-dodger-blue;
      margin-bottom: 31px;
    }

    &__action {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px dashed $color-dodger-blue;
      border-radius: $borderRadius;
      min-height: 40px;
      max-height: 40px;
      cursor: pointer;
      margin-top: 8px;
      transition: background-color .15s ease-in;

      span {
        font-family: $font-global-medium;
        font-size: $font-size-base;
        color: $color-dodger-blue;
      }

      &_disabled {
        border-color: $color-silver-chalice;
        pointer-events: none;

        span, i {
          color: $color-silver-chalice;
        }
      }

      i {
        margin-right: 4px;
      }

      &:hover {
        background: rgba($color-dodger-blue, .08);
      }
    }

    &-item {
      display: flex;
      margin-bottom: 8px;
      position: relative;

      &__currency, &__val, &__defis {
        margin-left: 2px;
        margin-right: 2px;
      }

      &__val {
        text-transform: capitalize;
        font-weight: bold;
      }

      &__content {
        border: 1px solid $color-gallery;
        border-radius: $borderRadius;
        max-width: 810px;
        width: 100%;
        display: flex;
        padding: 10px 12px;
        min-height: 49px;
        align-items: center;
        cursor: pointer;
        transition: background-color .2s ease-in;

        &:hover {
          background: rgba($color-dodger-blue, .08);
        }
      }

      &__icon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        color: $color-dodger-blue;

        &_close {
          font-size: $font-size-icon;
          color: $color-white;
          background: $color-dodger-blue;
        }

        i {
          color: $color-dodger-blue;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background: $color-dodger-blue;
          color: $color-white;
        }

        &_active {
          background: $color-dodger-blue;

          i {
            color: $color-white;
          }
        }
      }

      &__settings {
        display: flex;
        width: 35px;
        z-index: 5;
        position: relative;
        margin-left: 5px;
      }

      &__id {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: rgba($color-dodger-blue, .08);
        display: flex;
        justify-content: center;
        align-items: center;
        font: $font-size-sm $font-global-medium;
        color: $color-dodger-blue;
        margin-right: 6px;
      }

      &__title {
        width: 490px;
        font: $font-size-base $font-global-medium;
      }

      &__sum {
        padding: 5px 10px;
        height: 29px;
        color: $color-black;
        font: $font-size-md $font-global-medium;
        display: flex;
        max-width: 60%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .specification {

    &__list {

      &-item {
        margin-bottom: 8px;
      }
    }
  }
  .characteristics.uploadFile {
    position: relative;
    margin-top: 0;
    padding: 0 0 24px 0;
  }

  .characteristics {

    &-item {

      &__val {
        margin: 0 2px;
      }

      &__currency {
        margin-left: 4px;
      }

      &__icon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        color: $color-dodger-blue;

        &_close {
          font-size: $font-size-icon;
          color: $color-white;
          background: $color-dodger-blue;
        }

        i {
          color: $color-dodger-blue;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background: $color-dodger-blue;
          color: $color-white;
        }

        &_active {
          background: $color-dodger-blue;

          i {
            color: $color-white;
          }
        }
      }
    }

    &-list {

      &__item {
        margin-bottom: 8px;

        &__icon {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: background-color .15s ease-in;
          color: $color-dodger-blue;

          &_close {
            font-size: $font-size-icon;
            color: $color-white;
            background: $color-dodger-blue;
          }

          i {
            color: $color-dodger-blue;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:hover {
            background: $color-dodger-blue;
            color: $color-white;
          }

          &_active {
            background: $color-dodger-blue;

            i {
              color: $color-white;
            }
          }
        }
      }
    }

    &-item {

      &__settings {
        display: flex;
        width: 35px;
        z-index: 5;
        position: relative;
        margin-left: 5px;
      }

      &__content {
        border: 1px solid $color-gallery;
        border-radius: $borderRadius;
        max-width: 810px;
        width: 100%;
        display: flex;
        padding: 10px 12px;
        min-height: 49px;
        align-items: center;
        cursor: pointer;
        transition: background-color .2s ease-in;

        &:hover {
          background: rgba($color-dodger-blue, .08);
        }
      }

      &__id {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: rgba($color-dodger-blue, .08);
        display: flex;
        justify-content: center;
        align-items: center;
        font: $font-size-sm $font-global-medium;
        color: $color-dodger-blue;
        margin-right: 6px;
        min-width: 18px;
      }

      &__title {
        width: 490px;
        font: $font-size-base $font-global-medium;
      }

      &__sum {
        padding: 5px 10px;
        min-height: 29px;
        color: $color-black;
        font: $font-size-md $font-global-medium;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__list {

        &-item {
          margin-bottom: 8px;
        }
      }
    }
  }

  .steps {

    &__title {
      font: $font-size-h2 $font-global-medium ;
      line-height: 18px;
      color: $color-dodger-blue;
      margin-bottom: 6px;
    }

    &__scroll {
      margin-bottom: 8px;
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px dashed $color-dodger-blue;
      border-radius: $borderRadius;
      min-height: 40px;
      max-height: 40px;
      cursor: pointer;
      transition: background-color .15s ease-in;
      user-select: none;

      span {
        font: $font-size-base $font-global-medium;
        color: $color-dodger-blue;
      }

      i {
        color: $color-dodger-blue;
        margin-right: 4px;
      }

      &:hover {
        background: rgba($color-dodger-blue, .08);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &-item {

      &_active {
        position: relative;
        z-index: 100;
      }

      &-wrap {
        display: flex;
        padding-bottom: 8px;
        position: relative;
        align-items: center;
        z-index: 10;
        cursor: pointer;
      }

      &__content {
        border: 1px solid $color-gallery;
        border-radius: $borderRadius;
        max-width: 810px;
        width: 100%;
        display: flex;
        padding: 10px 12px;
        min-height: 49px;
        align-items: center;
        position: relative;
        z-index: -1;
        transition: background-color .2s ease-in;

        &:hover {
          background: rgba($color-dodger-blue, .08);
        }

      }

      &__left, &__right {
        display: flex;
      }

      &__right {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__left {
        max-width: 370px;
        width: 100%;
        align-items: center;

        &_width {
          max-width: 100%;
          min-width: 100%;
        }
      }

      &__icon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        color: $color-dodger-blue;

        &_close {
          font-size: $font-size-icon;
          color: $color-white;
          background: $color-dodger-blue;
        }

        i {
          color: $color-dodger-blue;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background: $color-dodger-blue;
          color: $color-white;
        }

        &_active {
          background: $color-dodger-blue;

          i {
            color: $color-white;
          }
        }
      }

      &__settings {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        z-index: 5;
        position: relative;
      }

      &__id {
        min-width: 18px;
        max-width: 18px;
        height: 18px;
        border-radius: 50%;
        background: rgba($color-dodger-blue, .08);
        display: flex;
        justify-content: center;
        align-items: center;
        font: $font-size-sm $font-global-medium;
        color: $color-dodger-blue;
        margin-right: 6px;
      }

      &__title {
        font: $font-size-base $font-global-medium;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &_width {
          max-width: 100%;
          white-space: normal;
        }
      }

      &__sum, &__days {
        display: flex;
        align-items: center;
        padding: 0 10px;
        background: rgba($color-dodger-blue, .08);
        height: 29px;
        font: $font-size-md $font-global-medium;
        color: $color-dodger-blue;
        border-radius: $borderRadius;
        max-width: 80px;
        min-width: 80px;
        margin-right: 143px;

        &-icon {
          position: relative;
          left: -5px;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__days {
        margin: auto;
      }
    }
  }
}

.action-elements {
  position: relative;
  left: 3px;
  font-size: $font-size-md;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: $color-dodger-blue;
  cursor: pointer;
  transition: all .15s ease-in;

  &-choose {
    user-select: none;
    position: absolute;
    top: 12px;
    right: 10px;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color-black, .1);
    border-radius: $borderRadius;
    background-color: $color-white;
    color: $color-black;
    cursor: default;
    min-width: 143px;
    max-width: 143px;
    padding: 3px 0;
    z-index: -1;

    &_lg {
      min-width: 346px;
      max-width: 346px;
    }

    &-controls {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__button {
        line-height: 1.5;
        min-height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color .15s ease-in;
        padding: 0 12px;
        font: $font-size-md $font-global;

        &:hover {
          background: $color-alabaster;
        }
      }
    }

    &-select {
      width: 100%;
      padding: 12px 16px;
    }
  }

  &:before {
    position: relative;
  }

  &:hover {
    background: $color-dodger-blue;
    color: $color-white;
    border-color: transparent;
  }
}

.ub-icon-cross {
  font-size: $font-size-xlg;
  color: $color-dodger-blue;

  &:before {
    position: relative;
    z-index: 5;
  }

  &:after {
    position: absolute;
    content: '';
    background: $color-white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    z-index: -1;
  }
}

.ub-icon-cross-x {
  background: $color-dodger-blue;
  color: $color-white;

  &:before {
    font-size: $font-size-h6;
    left: -.5px;
  }
}

.admin-form-field {
  position: relative;
}

</style>

<style lang="scss">
@import "../../sass/variables";

  .sortable-chosen {
    transition: transform 0.5s ease-in;
  }

  .sortable-ghost {
    opacity: 0;
    transition: transform 0.5s ease-in;
  }

  .specification-settings {

    &__wrapper {

      &_row {
        display: flex;
        align-items: center;
      }
    }

    &__wrap {
      width: 49%;
      border-radius: $borderRadius;
    }

    &__form {

      .ub-radio {

        & + .ub-radio {
          margin-left: 8px;
        }
      }

      .ub-checkbox {

        & + .ub-checkbox {
          margin-left: 15px;
        }
      }
    }
  }

  .characteristics {

    &-settings {
      width: 143px;
      min-width: 143px;
      height: 42px;
      box-shadow: 0 0 10px rgba($color-black, .1);
      border-radius: $borderRadius;
      position: absolute;
      right: 12px;
      top: 23px;
      z-index: -1;
      display: flex;
      justify-content: flex-start;
      background: $color-white;
      padding: 3px 0;
      align-items: center;
      cursor: pointer;

      &__item {
        padding: 9px 12px;
        width: 100%;
        transition: background-color .15s ease-in;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          background: $color-alabaster;
        }
      }

      &__text {
        font: $font-size-md $font-global;
      }
    }

  &__wrapper {

    &_row {
      display: flex;
      align-items: center;
    }
  }

  &__wrap {
    width: 49%;
    border-radius: $borderRadius;
  }

  &__form {

    .ub-radio {

      & + .ub-radio {
        margin-left: 15px;
      }
    }

    .ub-checkbox {

      & + .ub-checkbox {
        margin-left: 15px;
      }
    }
  }
}
</style>
