<template>
  <section class="admin-payments-page">
    <loader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
    <UBTable
      :columns="columns"
      :rows="paymentsList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 157px)'"
      :loader.sync="loader"
      :clickRow="false"
      @sort="getSortedPaginationData"
      @update="preUpdateRequest"
    />
    <UBPagination
      v-show="paymentsList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
  </section>
</template>

<script>
import listSettingsPayments from '@/constants/tablesSettings/listSettingsPayments';
import queryString from 'query-string';
import adminPageHeader from '@/components/adminPageHeader';
import loader from '@/components/loader';
import UBTable from '@/components/UBTable/UBTable';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import paymentsApi from '@/api/payments/paymentsApi';

export default {
  name: 'Partners',
  components: {
    adminPageHeader,
    loader,
    UBTable,
    UBPagination,
  },
  data() {
    return {
      paymentsListDef: [],
      loader: false,
      columns: listSettingsPayments,
      fullInfo: {},
      isVisiblePartnerModal: false,
      noData: {
        status: false,
        title: this.$t('paymentsPage.emptyPaymentsPlaceholder.titleNoData'),
      },
      queryParams: {
        page: 1,
        perPage: 20,
        search: '',
        sort: '',
      },
    };
  },
  computed: {
    headerPageOptions() {
      return {
        title: this.$t('paymentsPage.title'),
        search: {
          query: this.getSearchValue,
          value: this.queryParams.search,
        },
      };
    },
    paymentsList: {
      get() {
        return this.paymentsListDef;
      },
      set(data) {
        this.paymentsListDef = data;
      },
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      search = this.queryParams.search,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      search,
      sort: {
        paymentDate: -1,
      },
    };
    this.getRequests(this.queryParams);
  },
  methods: {
    getSortedPaginationData(data) {
      const { location } = window;
      const parsed = queryString.parse(location.search);
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
      } = parsed;
      this.queryParams = {
        page: +page < 1 ? 1 : +page,
        perPage: +perPage < 1 ? 1 : +perPage,
        search,
        sort: typeof data === 'string' ? {
          [`${data}`]: 1,
        } : data,
      };
      this.getRequests(this.queryParams);
    },
    preUpdateRequest({ row }) {
      const currentRow = this.paymentsList.find((el) => el.orderId._id === row.orderId);
      const currentRowIndex = this.paymentsList.findIndex((el) => el.orderId._id === row.orderId);
      const { orderId, clientId } = currentRow;
      const updatedRow = {
        ...currentRow,
        ...row,
        clientId,
        orderId,
      };
      this.paymentsList.splice(currentRowIndex, 1, updatedRow);
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    getSearchValue(search) {
      this.queryParams = {
        ...this.queryParams,
        search,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getRequests(queryParams) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
        sort = this.queryParams.sort,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        search,
        sort,
      };
      this.$router.push({
        query: {
          page,
          perPage,
          search: search || undefined,
          sort: JSON.stringify(sort) || undefined,
        },
      }).catch(() => {});
      this.loader = true;
      paymentsApi.getPaymentsHistory({
        page, perPage, search, sort,
      })
        .then((resp) => {
          this.loader = false;
          const { totalDocs, resource } = resp.data;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({
            totalDocs, page, perPage, sort,
          });
          this.paymentsList = [];
          const arr = [];
          resource.forEach((item) => {
            const {
              firstName,
              lastName = '',
              userAvatarUrl = '',
            } = item.clientId;
            const fullName = `${firstName} ${lastName}`;
            arr.push(
              {
                ...item,
                fullName,
                userAvatarUrl,
              },
            );
          });
          this.paymentsList = arr;
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">

.admin-partners-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
