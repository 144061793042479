const directorCompanyObj = [
  {
    text: ('servicesPage.addServiceModal.entity'),
    key: 'ENTITY',
  },
  {
    text: ('servicesPage.addServiceModal.individual'),
    key: 'NATURAL_PERSON',
  },
];

export default directorCompanyObj;
