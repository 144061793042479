<template>
  <section class="admin-services-list">
    <loader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
    />
    <UBTable
      :columns="columns"
      :rows="servicesList"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :noData="noData"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 157px)'"
      :loader.sync="loader"
      @update="preUpdateRequest"
      @delete="deleteRequest"
      @edit="openEditRequest"
      @copy="dublicateServices"
    />
    <UBPagination
      v-show="servicesList.length > 0"
      :data="fullInfo"
      :getQuery="getRequests"
      :limit="queryParams.perPage"
      :sticky="true"
    />
    <transition name="fade-el">
      <addNewServiceModal
        v-if="isVisibleNewServiceModal"
        :servicesList="servicesList"
        :editService="editService"
        @closeAddNewMenu="closeAddNewMenu"
      />
    </transition>
  </section>
</template>

<script>
import serviceApi from '@/api/services/servicesApi';
import UBTable from '@/components/UBTable/UBTable';
import UBPagination from '@/components/UBTable/UBPagination/UBPagination';
import listSettingsServices from '@/constants/tablesSettings/listSettingsServices';
import queryString from 'query-string';
import { findIndex } from 'lodash';
import countriesApi from '@/api/countries/countriesApi';
import adminPageHeader from '@/components/adminPageHeader';
import addNewServiceModal from '@/components/modals/addNewServiceModal';
import loader from '@/components/loader';

export default {
  name: 'Services',
  components: {
    UBTable,
    UBPagination,
    addNewServiceModal,
    adminPageHeader,
    loader,
  },
  data() {
    return {
      servicesListDef: [],
      loader: false,
      columns: listSettingsServices,
      fullInfo: {},
      isVisibleNewServiceModal: false,
      noData: {
        status: false,
        title: this.$t('servicesPage.placeholder.title'),
        description: this.$t('servicesPage.placeholder.text'),
        buttonText: this.$t('servicesPage.placeholder.buttonText'),
        buttonOnClick: this.showAddNewMenu,
      },
      queryParams: {
        page: 1,
        perPage: 20,
        search: '',
        filter: '',
      },
      editService: {},
    };
  },
  computed: {
    headerPageOptions() {
      return {
        title: this.$t('servicesPage.title'),
        button: {
          text: this.$t('servicesPage.buttonText'),
          onClick: this.showAddNewMenu,
        },
        search: {
          query: this.getSearchValue,
          value: this.queryParams.search,
        },
        filter: {
          options: this.countrySelectSettings,
          change: this.changeCountryFilter,
        },
      };
    },
    countrySelectSettings() {
      return {
        optionList: this.selectCountriesList,
        placeholderText: this.$t('countryPage.placeholderSelect'),
        selectedKey: this.queryParams.filter,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: '',
        imagePosition: 'left',
        showImageInput: true,
      };
    },
    selectCountriesList() {
      const arr = [];
      arr.push({
        key: '',
        text: this.$t('servicesPage.table.filter.allCountry'),
      });
      this.createdCountriesList.forEach((item) => {
        arr.push({
          text: item.name[this.language],
          key: item._id,
          image: `country/${item.code}.svg`,
        });
      });
      return arr;
    },
    servicesList: {
      get() {
        return this.servicesListDef;
      },
      set(data) {
        this.servicesListDef = data;
      },
    },
    allCountriesList: {
      get() {
        return this.$store.getters.allCountriesList;
      },
      set(data) {
        this.$store.dispatch('setAllCountriesList', data);
      },
    },
    createdCountriesList() {
      return this.allCountriesList.filter((item) => item.isVisible);
    },
    language() {
      return this.$i18n.locale;
    },
  },
  async beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      page = this.queryParams.page,
      perPage = this.queryParams.perPage,
      search = this.queryParams.search,
      filter = this.queryParams.filter,
    } = parsed;
    this.queryParams = {
      page: +page < 1 ? 1 : +page,
      perPage: +perPage < 1 ? 1 : +perPage,
      search,
      filter,
    };
    if (this.allCountriesList.length === 0) {
      await countriesApi.getCreatedCountries().then((resp) => {
        const arr = [];
        resp.data.forEach((item) => {
          arr.push(
            {
              ...item,
              image: `country/${item.code}.svg`,
            },
          );
        });
        this.allCountriesList = arr;
      }).catch((err) => {
        console.error(err);
      });
    }
    this.getRequests(this.queryParams);
  },
  methods: {
    dublicateServices(id) {
      this.loader = true;
      serviceApi.copyService(id).then((resp) => {
        this.loader = false;
        const { data } = resp;
        this.servicesList.unshift({
          ...data,
          country: {
            ...data.country,
            image: this.getOptionImage(data.country.code),
          },
        });
      }).catch((err) => {
        this.loader = false;
        console.error(err);
      });
    },
    changeCountryFilter(filter) {
      this.queryParams = {
        ...this.queryParams,
        filter,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    getPaginationData({ totalDocs, page, perPage }) {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      this.fullInfo = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs,
      };
    },
    getSearchValue(search) {
      this.queryParams = {
        ...this.queryParams,
        search,
        page: 1,
      };
      this.getRequests(this.queryParams);
    },
    getRequests(queryParams) {
      const {
        page = this.queryParams.page,
        perPage = this.queryParams.perPage,
        search = this.queryParams.search,
        filter = this.queryParams.filter,
      } = queryParams;
      this.queryParams = {
        page,
        perPage,
        search,
        filter,
      };
      const filterQuery = filter ? JSON.stringify({ 'country._id': filter }) : undefined;
      this.$router.push({
        query: {
          page,
          perPage,
          search: search || undefined,
          filter: filter || undefined,
        },
      }).catch(() => {});
      this.loader = true;
      serviceApi.getServicesList({
        page, perPage, filter: filterQuery, search,
      })
        .then((resp) => {
          this.loader = false;
          const { data: { totalDocs, resource } } = resp;
          this.noData = {
            ...this.noData,
            status: totalDocs === 0,
          };
          this.getPaginationData({ totalDocs, page, perPage });
          this.servicesList = [];
          resource.forEach((service) => {
            const { steps } = service;
            Object.keys(steps).forEach((key) => {
              steps[key].forEach((step) => {
                if (!step.cost) {
                  step.cost = '';
                }
                if (!step.timing) {
                  step.timing = '';
                }
              });
            });
            this.servicesList.push({
              ...service,
              steps,
              country: {
                ...service.country,
                image: this.getOptionImage(service.country.code),
              },
            });
          });
        }).catch((err) => {
          this.loader = false;
          console.error(err);
        });
    },
    preUpdateRequest() {
    },
    removeServices(id) {
      serviceApi.deleteService(id)
        .then()
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteRequest(service) {
      const { row: { _id } } = service;
      const { page, totalDocs } = this.fullInfo;
      const { perPage } = this.queryParams;
      const serviceIndex = findIndex(this.servicesList, (item) => item._id === _id);
      this.servicesList.splice(serviceIndex, 1);
      if (this.servicesList.length === 0) {
        await this.removeServices(_id);
        const newPage = page > 1 ? page - 1 : page;
        this.queryParams = {
          ...this.queryParams,
          page: newPage,
        };
        this.getRequests(this.queryParams);
      } else {
        this.getPaginationData({ totalDocs: totalDocs - 1, page, perPage });
        this.removeServices(_id);
      }
    },
    showAddNewMenu() {
      this.isVisibleNewServiceModal = true;
    },
    closeAddNewMenu() {
      this.isVisibleNewServiceModal = false;
      this.editService = {};
    },
    openEditRequest(el) {
      this.editService = el.row;
      this.isVisibleNewServiceModal = true;
    },
  },
};
</script>

<style scoped lang="scss">

  .admin-services-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
</style>
