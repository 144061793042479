import { uniqueId } from 'lodash';

const columns = [
  {
    title: 'partnersPage.table.columns.name',
    prop: 'partnerCompanyName',
    width: 'minmax(70px, 1fr)',
  },
  {
    title: 'partnersPage.table.columns.country',
    type: 'image',
    prop: 'country',
    displayTitle: 'country',
    width: 'minmax(70px, 1fr)',
  },
  {
    title: 'partnersPage.table.columns.phone',
    prop: 'phone',
    width: 'minmax(70px, 0.8fr)',
  },
  {
    title: 'partnersPage.table.columns.amountOfDeals',
    width: 'minmax(70px, 0.8fr)',
    prop: 'ordersCount',
    justifyContent: 'center',
  },
  // {
  //   title: 'partnersPage.table.columns.amountOfTransactions',
  //   pointer: true,
  //   width: 'minmax(70px, 1.1fr)',
  //   prop: 'ordersSum',
  // },
  {
    type: 'toolbar',
    prop: 'partnerCompanyName',
    width: '26px',
  },
];

const listSettingsPartners = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listSettingsPartners;
